import React, {FormEvent, MouseEvent, useEffect, useRef} from 'react';
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {ModalFieldLabel} from "../../Modal/ModalFieldLabel.tsx";
import {InputText} from "primereact/inputtext";
import {ErrorField} from "../../ErrorField/ErrorField.tsx";
import {RepeatableInputPanel} from "../PipelineInfo/RepeatableInputPanel.tsx";
import {FieldArray, Form, Formik, FormikHelpers, FormikValues} from "formik";
import {RepeatableInputFormGroup} from "../PipelineInfo/RepeatableInputFormGroup.tsx";
import {ExecutableLinkFormInput} from "../PipelineInfo/ExecutableLinkFormInput.tsx";
import {ToolDocumentationFormInput} from "../PipelineInfo/ToolDocumentationFormInput.tsx";
import {FormikProps} from "formik/dist/types";
import { SelectItemOptionsType } from "primereact/selectitem";
import {AddToolDto, useAddTool} from "../../../hooks/useAddTool.tsx";
import {Toast} from "primereact/toast";
import {consoleWrap} from "../../../main.tsx";
import {IToolTableDataResponse} from "../../../hooks/useGetToolTableData.tsx";

interface IToolInfoModalProps {
    onHide: () => void;
    visible: boolean;
    institutionOptions: SelectItemOptionsType;
    onSuccess: (newTool: AddToolDto) => void;
}

interface FormValues {
    toolName: string;
    toolPurpose: string;
    inputFileTypeDropdown: string;
    outputFileTypeDropdown: string;
    version: string;
    executableLinks: { executableLink: string; linkType: string }[];
    toolDocumentation?: string[];
    doi: string;
    institution: string[];
}

export function ToolInfoModal(props: IToolInfoModalProps) {
    const formRef = useRef<FormikProps<FormValues>>(null);
    const { addTool, isLoading, error, data } = useAddTool(); // Use the custom hook
    const toast = useRef<Toast>(null);


    useEffect(() => {
        if (data) {
            consoleWrap.log('Tool added successfully:', data);
            toast.current?.show({ severity: 'success', summary: 'Success', detail: 'Success! Tool created' });
            props.onSuccess(data);  // Trigger the onSuccess callback with the new tool data
            props.onHide();  // Close modal after success
        }
    }, [data]);

    useEffect(() => {
        if (error) {
            console.error('Failed to add tool:', error);
            if (error.response?.status === 422 && formRef.current) {
                formRef.current.setErrors({
                    toolName: 'This tool name matches an existing tool in our database. Consider searching the existing tool or choose another tool name.'
                });
            } else {
                toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Failed to create tool.' });
            }
        }
    }, [error]);

    const handleCancel = () => {
        props.onHide();
    }

    const dialogFooter = (
        <div className="w-full h-full bg-white justify-end items-center gap-3 inline-flex">
            <Button
                data-testid={'cancelBtn'}
                className="px-3 !py-2 !bg-white !text-gray-800 font-inter rounded shadow border border-zinc-400 justify-center items-center gap-2.5 flex"
                onClick={handleCancel}>
                Cancel
            </Button>
            <Button
                data-testid={'createPipelineBtn'}
                className="!py-2 !bg-blue-700 text-white font-inter rounded shadow border border-blue-700 justify-center items-center gap-2.5 flex"
                onClick={() => formRef.current?.submitForm()}
                disabled={isLoading} // Disable button if loading
            >
                Create new tool
            </Button>
        </div>
    );

    const validateForm = (values: FormValues) => {
        const errors: Partial<FormValues> = {};

        if (!values.toolName) {
            errors.toolName = 'Tool Name is required';
        }
        if (!values.toolPurpose) {
            errors.toolPurpose = 'Tool Purpose is required';
        }
        if (values.executableLinks && values.executableLinks.length > 0) {
            const executableLinkErrors: any[] = values.executableLinks.map((link: any) => {
                const linkErrors: any = {};
                if (!link.executableLink || !link.linkType) {
                    if (!link.executableLink) {
                        linkErrors.executableLink = "Executable Link is required";
                    }
                    if (!link.linkType) {
                        linkErrors.linkType = "Executable Type is required";
                    }
                }
                return linkErrors;
            });

            if (executableLinkErrors.some(error => Object.keys(error).length > 0)) {
                errors.executableLinks = executableLinkErrors;
            }
        }
        if (!values.toolDocumentation || values.toolDocumentation.some(doc => !doc)) {
            errors.toolDocumentation = ['Tool Documentation is required'];
        }
        return errors;
    };

    const handleFormSubmission = async (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
        const toolData: AddToolDto = {
            tool: {
                name: values.toolName,
                versions: [values.version],
                purposes: [
                    {
                        text: values.toolPurpose,
                        executableLinks: values.executableLinks.map(link => ({
                            executableLink: link.executableLink,
                            linkType: link.linkType,
                            default: false,
                        })),
                    },
                ],
                input: [values.inputFileTypeDropdown],
                output: [values.outputFileTypeDropdown],
                link: values.toolDocumentation?.filter(doc => typeof doc === 'string' && doc.trim() !== '') || [],
                doi: values.doi,
                user: values.institution,
            },
        };

        await addTool(toolData);
        formikHelpers.setSubmitting(false);  // Stop the form submission process
    };

    return (
        <div>
            <Toast ref={toast} />
            <Dialog
                header="Create a new tool"
                visible={props.visible}
                onHide={props.onHide}
                closable={false}
                footer={dialogFooter}
                className="w-[50vw]"
                pt={{
                    header: { className: "border-b border-t border-gray-200" },
                    content: { className: "max-h-[75vh] !overflow-y-scroll" },
                    footer: { className: "border-t border-b border-gray-200 !py-4" }
                }}
            >
                <Formik<FormValues>
                    initialValues={{
                        toolName: "",
                        toolPurpose: "",
                        inputFileTypeDropdown: "",
                        outputFileTypeDropdown: "",
                        version: "",
                        executableLinks: [],
                        toolDocumentation: [""],
                        doi: "",
                        institution: [""],

                    }}
                    validate={validateForm}
                    onSubmit={handleFormSubmission}
                    innerRef={formRef}
                >
                    {formik => (
                        <Form
                            onKeyDown={(e: { key: string; preventDefault: () => any; }) => e.key === 'Enter' && e.preventDefault()}
                            onSubmit={formik.handleSubmit}>
                            <div className="w-full flex-col justify-start gap-4 inline-flex items-stretch pt-5">
                                { /* Name Field */}
                                <div className="flex-col justify-start items-start gap-1 flex">
                                    <ModalFieldLabel label={"Tool Name"} required={true} />
                                    <InputText
                                        data-testid={'toolName'}
                                        placeholder="New Tool"
                                        {...formik.getFieldProps("toolName")}
                                        className={`self-stretch px-3 py-2.5 bg-white rounded border justify-start items-center gap-2.5 inline-flex ${
                                            formik.submitCount > 0 && formik.errors.toolName ? "border-red-500" : "border-zinc-400"
                                        }`}
                                        pt={{
                                            root: {
                                                className: "!text-gray-800"
                                            }
                                        }}
                                    />
                                    {formik.errors.toolName && (
                                        <ErrorField errorMessage={formik.errors.toolName} />
                                    )}
                                </div>

                                { /* Purpose Field */}
                                <div className="flex-col justify-start items-start gap-1 flex">
                                    <ModalFieldLabel label={"Purpose"} required={true} />
                                    <InputText
                                        data-testid={'purposeName'}
                                        placeholder="Purpose of the tool"
                                        {...formik.getFieldProps("toolPurpose")}
                                        className={`self-stretch px-3 py-2.5 bg-white rounded ${
                                            formik.submitCount > 0 && formik.errors.toolPurpose ? "border-red-500" : "border-zinc-400"
                                        } justify-start items-center gap-2.5 inline-flex`}
                                        pt={{
                                            root: {
                                                className: "!text-gray-800"
                                            }
                                        }}
                                    />
                                    {formik.errors.toolPurpose && formik.submitCount > 0 && (
                                        <ErrorField errorMessage={formik.errors.toolPurpose} />
                                    )}
                                </div>

                                { /* Input File Type Field */}
                                <div className="flex-col justify-start items-start gap-1 flex">
                                    <ModalFieldLabel label={"Input File Type"} />
                                    <InputText
                                        data-testid={'inputFileTypeDropdown'}
                                        placeholder="Input File Type"
                                        {...formik.getFieldProps("inputFileTypeDropdown")}
                                        className="self-stretch px-3 py-2.5 bg-white rounded border border-zinc-400 justify-start items-center gap-2.5 inline-flex"
                                        pt={{
                                            root: {
                                                className: "!text-gray-800"
                                            }
                                        }}
                                    />
                                    {formik.errors.inputFileTypeDropdown && formik.submitCount > 0 && (
                                        <ErrorField errorMessage={formik.errors.inputFileTypeDropdown} />
                                    )}
                                </div>

                                { /* Output File Type Field */}
                                <div className="flex-col justify-start items-start gap-1 flex">
                                    <ModalFieldLabel label={"Output File Type"} />
                                    <InputText
                                        data-testid={'outputFileTypeDropdown'}
                                        placeholder="Output File Type"
                                        {...formik.getFieldProps("outputFileTypeDropdown")}
                                        className="self-stretch px-3 py-2.5 bg-white rounded border border-zinc-400 justify-start items-center gap-2.5 inline-flex"
                                        pt={{
                                            root: {
                                                className: "!text-gray-800"
                                            }
                                        }}
                                    />
                                    {formik.errors.outputFileTypeDropdown && formik.submitCount > 0 && (
                                        <ErrorField errorMessage={formik.errors.outputFileTypeDropdown} />
                                    )}
                                </div>

                                { /* Version Field */}
                                <div className="flex-col justify-start items-start gap-1 flex">
                                    <ModalFieldLabel label={"Version"} />
                                    <InputText
                                        data-testid={'version'}
                                        {...formik.getFieldProps("version")}
                                        className="self-stretch px-3 py-2.5 bg-white rounded border border-zinc-400 justify-start items-center gap-2.5 inline-flex"
                                        pt={{
                                            root: {
                                                className: "!text-gray-800"
                                            }
                                        }}
                                    />
                                    {formik.errors.version && formik.submitCount > 0 && (
                                        <ErrorField errorMessage={formik.errors.version} />
                                    )}
                                </div>

                                { /* Executable links Field */}
                                <RepeatableInputPanel
                                    name={"executables"}
                                    dataTestId={"executablesAddOptionBtn"}
                                    headerLabel={"Executables"}
                                    onAddAnotherOption={() => {
                                        formik.setFieldValue('executableLinks', [...formik.values.executableLinks, { executableLink: "", linkType: "", default: false }]);
                                    }}
                                >
                                    <FieldArray name={"executableLinks"}>
                                        {({ remove }) => (
                                            formik.values.executableLinks.map((val: any, index: number) => (
                                                <div key={`ExecutableLinkFormInput-${index}`}>
                                                    <ExecutableLinkFormInput
                                                        name="executableLinks"
                                                        placeholder="Link to Executable"
                                                        onDelete={remove}
                                                        index={index}
                                                    />
                                                </div>
                                            ))
                                        )}
                                    </FieldArray>
                                </RepeatableInputPanel>

                                { /* Tool Documentation Field */}
                                <div className="flex-col justify-start items-start gap-1 flex">
                                    <RepeatableInputPanel
                                        name={ "toolDocumentation" }
                                        headerLabel={ "Tool Documentation" }
                                        onAddAnotherOption={ () => {
                                            formik.setFieldValue('toolDocumentation', [...( formik.values.toolDocumentation || [] ), '']);
                                        } }
                                    >
                                        <FieldArray name={ "toolDocumentation" }>
                                            { ({ remove }) => (
                                                formik.values.toolDocumentation?.map((val: any, index: number) => (
                                                    <ToolDocumentationFormInput
                                                        key={ index }
                                                        onDelete={ () => remove(index) }
                                                        name={ `toolDocumentation` }
                                                        index={ index }
                                                        required={true}
                                                    />
                                                ))
                                            ) }
                                        </FieldArray>

                                    </RepeatableInputPanel>
                                </div>

                                { /* DOI Field */}
                                <div className="flex-col justify-start items-start gap-1 flex">
                                    <ModalFieldLabel label={"DOI"} />
                                    <InputText
                                        data-testid={'doi'}
                                        {...formik.getFieldProps("doi")}
                                        className="self-stretch px-3 py-2.5 bg-white rounded border border-zinc-400 justify-start items-center gap-2.5 inline-flex"
                                        pt={{
                                            root: {
                                                className: "!text-gray-800"
                                            }
                                        }}
                                    />
                                    {formik.errors.doi && formik.submitCount > 0 && (
                                        <ErrorField errorMessage={formik.errors.doi} />
                                    )}
                                </div>

                                { /* Lab/Institution Field */}
                                <RepeatableInputPanel
                                    name={"labGroups"}
                                    dataTestId={"labGroupsAddOptionBtn"}
                                    headerLabel={"Lab Group/Institution" }
                                    onAddAnotherOption={ () => {
                                        formik.setFieldValue('institution', [...formik.values.institution, ""])
                                    } }
                                >
                                    <FieldArray name={ "institution" }>
                                        { ({ remove }) => {
                                            return (
                                                formik.values.institution.map((val: any, index: number) => {
                                                    return (
                                                        <RepeatableInputFormGroup
                                                            key={ `InstitutionFormInput-${ index }` }
                                                            onDelete={ remove }
                                                            name="institution"
                                                            index={ index }
                                                            dropdownOptions={ props.institutionOptions }
                                                            text="Lab Group/Institution"
                                                        />
                                                    );
                                                })
                                            );
                                        } }
                                    </FieldArray>
                                </RepeatableInputPanel>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    );
}

export default ToolInfoModal;