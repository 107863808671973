import { useAxios } from "../useAxios.tsx";
import { doRefreshToken } from "../useRefreshToken.tsx";
import { DraftStatus } from "../../models/Builder/DraftStatusEnum.ts";

export interface RequestChangesPipelineDraftDataResponse {
    "newStatus": DraftStatus.Changes_Requested;
}

type RequestChangesPipelineDraftAxiosResponse = {
    data: RequestChangesPipelineDraftDataResponse | null;
    error: any;
    isLoading: boolean;
    commonFetch: (options: any) => Promise<any>;
}

export const useRequestChangesPipelineDraft = () => {
    const { commonFetch, isLoading, data, error }: RequestChangesPipelineDraftAxiosResponse = useAxios({
        url: '/view/pipeline-builder/request-changes',
        method: "POST",
    });

    const requestChangesPipelineDraft = async (pipelineId: string, email: string) => {
        try {
            await doRefreshToken();
            await commonFetch({ input: { pipelineId, email } });
        } catch (e) {
            console.error("Failed to refresh token or request changes for pipeline draft", e);
            throw e;
        }
    };

    return { requestChangesPipelineDraft, isLoading, data, error };
};
