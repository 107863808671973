import React, { useEffect } from "react";
import { useSelector} from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ReactSVG } from "react-svg";
import IMAGES from "../../assets/images/images.tsx";
import { Button } from "primereact/button";
import { DraftStatus } from "../../models/Builder/DraftStatusEnum.ts";
import { RootState } from "../../store/store.ts";
import { PipelineDraftStatusTag } from "../PipelineDraft/PipelineDraftStatusTag.tsx";
import { AdminReviewButtons } from "../AdminTable/AdminReviewButtons.tsx";
import {jwtDecode} from 'jwt-decode';



interface PipelineExpandedDiagramTopBarProps {
    pipelineName: string,
    submissionHandler: () => void,
    requestChangesHandler: (id: string, email: string) => void,
    approveHandler: (id: string, email: string) => void,
    status: DraftStatus
}

export const TopBar = (props: PipelineExpandedDiagramTopBarProps) => {

    const navigate = useNavigate();

    const readOnly = useSelector((state: RootState) => state.builderMeta.readOnly);
    const { _id: pipelineId } = useSelector((state: RootState) => state.builderSaveDraft);
    const [userIsAdmin, setUserIsAdmin] = React.useState(false);
    const [userEmail, setUserEmail] = React.useState('');

    const [searchParams] = useSearchParams();

    const fromSearchParam = searchParams.get('from')

    useEffect(() => {
        const jwtTokenString = localStorage.getItem('cobe-accessToken');
        if (jwtTokenString) {
            const jwtToken: any = jwtDecode(jwtTokenString);
            setUserEmail(jwtToken?.email);
            if (jwtToken?.isAdmin === true) {
                setUserIsAdmin(true);
            }
        }
    }, []);

    const pageBack = () => {
        navigate(-1)
    }

    const approvePipeline = () => {
        if (pipelineId && userEmail) {
            props.approveHandler(pipelineId, userEmail)
        }
    }

    const requestReviewPipeline = () => {
        if (pipelineId && userEmail) {
            props.requestChangesHandler(pipelineId, userEmail)
        }
    }

    return (
        <div
            className="w-full h-20 px-5 py-4 bg-white border-b border-gray-200 justify-between items-center inline-flex">
            <div className="grow shrink basis-0 h-10 justify-start items-center gap-2 flex">
                <div
                    className="p-2 rounded-[32px] justify-start items-start gap-2.5 flex hover:bg-icon_button_hover cursor-pointer"
                    onClick={ pageBack }>
                    <div className="w-6 h-6 relative">
                        <div className="w-6 h-6 left-0 top-0 absolute">
                            <ReactSVG src={ IMAGES.left_arrow_icon }/>
                        </div>
                    </div>
                </div>
                <div className="text-center text-black text-xl font-semibold font-inter leading-normal">
                    { props.pipelineName }
                </div>
                <PipelineDraftStatusTag status={props.status} />
            </div>
            <div className="justify-start items-center gap-4 flex">
                {/*{ !readOnly && fromSearchParam === 'builder' &&*/}
                {/*    <div className="text-center text-neutral-500 text-sm font-normal font-inter leading-tight">*/}
                {/*        All changes saved PLACEHOLDER*/}
                {/*    </div>*/}
                {/*}*/}
                { !readOnly && fromSearchParam === 'builder' &&
                    <Button
                        data-testid={ 'submitDraftBtn' }
                        className="h-8 !py-2 !bg-blue-700 text-white rounded shadow border border-blue-700 justify-center items-center gap-2.5 flex !font-inter text-sm font-semibold leading-none"
                        onClick={ props.submissionHandler }>
                        Submit
                    </Button>
                }
                { fromSearchParam === 'review' && userIsAdmin &&
                    <AdminReviewButtons
                      approvePipelineDraft={ approvePipeline }
                      requestReviewPipelineDraft={ requestReviewPipeline }
                    />
                }
            </div>
        </div>
    );
};
