import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Formik } from "formik";
import { RootState } from "../../store/store.ts";
import { ModalFieldLabel } from "../Modal/ModalFieldLabel.tsx";
import IMAGES from "../../assets/images/images.tsx";

interface AdminSplitButtonProps {
    approvePipelineDraft: () => void;
    requestReviewPipelineDraft: () => void;
}

export const AdminReviewButtons: React.FC<AdminSplitButtonProps> = (props: AdminSplitButtonProps) => {
    const [searchParams] = useSearchParams();

    const [viewModal, setViewModal] = useState(false)
    const pipelineId = searchParams.get('id');
    const { rows } = useSelector((state: RootState) => state.builderSubmittedPipelines);

    const pipeline = rows.find(row => row.id === pipelineId);

    const handleFormSubmission = () => {
        props.requestReviewPipelineDraft();
        setViewModal(false);
    }

    const handleCancel = () => {
        setViewModal(false);
    }

    const dialogFooter = (
        <div
            className="w-full h-full bg-white justify-end items-center gap-3 inline-flex">
            <Button
                data-testid={'cancelBtn'}
                className="px-3 !py-2 !bg-white !text-gray-800 font-inter rounded shadow border border-zinc-400 justify-center items-center gap-2.5 flex"
                onClick={ handleCancel }>
                Cancel
            </Button>
            <Button
                data-testid={'send-request-review'}
                tooltip="Ctrl + Enter or Shift + Enter to Send"
                className="!py-2 !bg-blue-700 text-white font-inter rounded shadow border border-blue-700 justify-center items-center gap-2.5 flex"
                onClick={ handleFormSubmission }>
                Send
            </Button>
        </div>
    );

    return (
        <>
            <Button
                className="h-8 !py-2 rounded shadow border border-gray-200 justify-center items-center gap-2.5 flex !font-inter text-sm font-semibold leading-none"
                onClick={() => setViewModal(true)}>
                <ReactSVG src={ IMAGES.send_icon } className="w-5 h-6 text-gray-600"/>
                Request Changes
            </Button>
            <Button
                className="h-8 !py-2 !bg-blue-700 text-white rounded shadow border border-blue-700 justify-center items-center gap-2.5 flex !font-inter text-sm font-semibold leading-none"
                onClick={() => props.approvePipelineDraft()}>
                <ReactSVG src={ IMAGES.checkmark_circle_icon } className="w-5 h-6 text-gray-600"/>
                Approve Pipeline
            </Button>
            <Dialog
                header={"Request Changes"}
                visible={viewModal}
                onHide={() => setViewModal(false)}
                footer={dialogFooter}
            >
                <Formik
                    initialValues={ {
                      to: pipeline?.userEmail,
                      pipeline: pipeline?.name,
                      subject: "CHANGE REQUESTED for your CoBE pipeline",
                      message: ""
                    } }
                    onSubmit={handleFormSubmission}
                >
                    { formik => (
                        <form
                        onKeyDown={(e) => {
                            if ((e.ctrlKey || e.shiftKey) && e.key === 'Enter') {
                                e.preventDefault();
                                formik.handleSubmit();
                            }
                        }}
                            onSubmit={ formik.handleSubmit }
                        >
                            <div className="flex-col justify-start items-start gap-1 flex">
                                <ModalFieldLabel label="To" />
                                <InputText
                                    data-testid={"to"}
                                    placeholder="account@email.com"
                                    disabled
                                    {...formik.getFieldProps("to")}
                                    className={`self-stretch px-3 py-2.5 mb-3 bg-white rounded border justify-start items-center inline-flex ${formik.submitCount > 0 && formik.errors.to ? "border-red-500" : "border-zinc-400"}`}
                                />
                                <ModalFieldLabel label="Pipeline" />
                                <InputText
                                    data-testid={"pipeline"}
                                    placeholder="ATAC-seq"
                                    disabled
                                    {...formik.getFieldProps("pipeline")}
                                    className={`self-stretch px-3 py-2.5 mb-3 bg-white rounded border justify-start items-center inline-flex ${formik.submitCount > 0 && formik.errors.pipeline ? "border-red-500" : "border-zinc-400"}`}
                                />
                                <ModalFieldLabel label="Subject" />
                                <InputText
                                    data-testid={"subject"}
                                    {...formik.getFieldProps("subject")}
                                    className={`self-stretch px-3 py-2.5 mb-3 bg-white rounded border justify-start items-center inline-flex ${formik.submitCount > 0 && formik.errors.subject ? "border-red-500" : "border-zinc-400"}`}
                                />
                                <ModalFieldLabel label="Message" />
                                <InputTextarea
                                    placeholder="Write a message..."
                                    rows={5}
                                    {...formik.getFieldProps("message")}
                                    className={`self-stretch px-3 py-2.5 mb-3 bg-white rounded border justify-start items-center inline-flex ${formik.submitCount > 0 && formik.errors.message ? "border-red-500" : "border-zinc-400"}`}
                                />
                            </div>
                        </form>
                    )}
                </Formik>
            </Dialog>
        </>
    );
};
