import { getNodes } from "../../helpers/PipelineNodeDetailList/NodeDetailListHelpers.ts";
import React from "react";
import { toolType } from "../../pages/Home/PipelineDetailPage.tsx";
import { IFormattedData } from "../../models/IFormattedData.ts";
import { IInfoNode, INodeInfo } from "../../models/Graph/IInfoNode.ts";
import {CodeLinkSplitButton} from "../CodeLinkButton/CodeLinkSplitButton.tsx";

interface PipelineNodeDetailListProps {
    formattedData: IFormattedData;
}

export const PipelineNodeDetailList = (props: PipelineNodeDetailListProps) => {

    // get nodes that make up the selected pipeline
    const { pipelineNodes, forks } = getNodes(props.formattedData.pipelines[0], props.formattedData.graph);

    const getFormattedNode = (node: IInfoNode) => {
        if (toolType.includes(node.type)) {
            return ToolNode(node);
        } else {
            return FileNode(node);
        }
    };

    const ToolNode = (node: IInfoNode) => (
        <div>
            <div>
                <p className="text-base font-semibold font-inter text-neutral-800 leading-tight">
                    { node.name }
                    { node.info.version ? ` (${ node.info.version })` : "" }
                </p>
            </div>
            <div className="pb-5 max-w-full overflow-x-hidden">
                <ToolInfoView toolInfo={ node.info }/>
            </div>
        </div>
    );

    const FileNode = (node: IInfoNode) => (
        <div>
            <div>
                [Data] <b>{ node.name }</b>
            </div>
        </div>
    );

    const ToolInfoView = (props: { toolInfo: INodeInfo }) => {
        const { toolInfo } = props;
        const links = toolInfo?.executableLinks;

        return (
            <div className="text-neutral-800 font-normal flex flex-col">
                { ( toolInfo.keywords && toolInfo.keywords.length > 0 ) &&
                    <ToolInfoViewLine label={ "Keywords" } value={ toolInfo.keywords.join(', ') }></ToolInfoViewLine>
                }
                <ToolInfoViewLine label={ "Purpose" }
                                  value={ toolInfo.purpose ? toolInfo.purpose : 'Unavailable' }/>
                <ToolInfoViewLine label={ "Input" }
                                  value={ toolInfo.input.length > 0 ? toolInfo.input.join(', ') : 'Unavailable' }/>
                <ToolInfoViewLine label={ "Output" }
                                  value={ toolInfo.output.length > 0 ? toolInfo.output.join(', ') : 'Unavailable' }/>
                {/*<ToolInfoViewLine label={ "Score" } value={ toolInfo.score ? toolInfo.score : 0 }/>*/}
                <div className="flex flex-row items-center justify-start gap-2">
                    <p className="justify-start items-start gap-2 inline-flex text-sm h-fit font-inter text-gray-700">Executable:</p>
                    <div className="flex items-center grow">
                        {toolInfo.executableLinks.length > 0 ?
                            <span className="text-base">
                                <CodeLinkSplitButton executableLinks={links} />
                            </span> : 'Unavailable'}
                    </div>
                </div>
                <ToolInfoViewLine label={ "Link" } value={ toolInfo.link ?
                    <HyperLink link={ toolInfo.link } text={ toolInfo.link }/> : 'Unavailable' }/>
                <ToolInfoViewLine label={ "DOI" } value={ toolInfo.doi ?
                    <HyperLink link={ toolInfo.doi } text={ toolInfo.doi }/> : 'Unavailable' }/>
            </div>
        );
    }

    const ToolInfoViewLine = (props: { label: string, value: any }) => {
        const { label, value } = props;
        return (
            <div className=" justify-start items-start gap-2 inline-flex text-sm h-fit ">
                <div className=" w-20 text-neutral-500 min-w-20 ">
                    <p className="font-inter text-gray-700">{ label }:</p>
                </div>
                <div className="grow shrink basis-0 text-neutral-800  ">
                    { value }
                </div>
            </div>
        );
    }

    const HyperLink = (props: { link: string, text: string }) => {
        const { link, text } = props;
        return (
            <div>
                <a href={ link }
                   rel='nonreferrer noopener'
                   target={ self ? '_self' : '_blank' }
                   className="text-blue-700 text-sm font-normal leading-tight break-all">
                    { text }
                </a>

            </div>
        );
    }

    return (
        <div>
            <div>
                <div>
                    { pipelineNodes.map((node: IInfoNode, i: number) => (
                        <div key={ i }>{ getFormattedNode(node) }</div>
                    )) }
                </div>
            </div>
            {
                forks.map((fork, i) => (
                    <div key={ i }>
                        <span className="section-title">
                          FORK { i + 1 }: { fork.title }
                        </span>
                        <div>
                            { fork.nodes.map((node: IInfoNode, i: number) => (
                                <div key={ i }>{ getFormattedNode(node) }</div>
                            )) }
                        </div>
                    </div>
                ))
            }
        </div>
    );
};
