import React, {useCallback, useEffect, useMemo, useRef} from "react";
import {
    applyEdgeChanges,
    applyNodeChanges,
    Background,
    Controls,
    Edge,
    MarkerType,
    Node,
    Panel,
    ReactFlow,
    useEdgesState,
    useNodesState
} from "reactflow";
import { graphlib } from "dagre-d3-es";
import { layout } from "dagre-d3-es/src/dagre";


import 'reactflow/dist/style.css';
import { BaseNode } from "./CustomNodes/BaseNode.tsx";
import { ToolNode, ToolNodeData } from "./CustomNodes/ToolNode.tsx";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { PipelineExpandedDiagramLocationState } from "../../pages/Home/PipelineExpandedDiagramPage.tsx";
import { IFormattedData } from "../../models/IFormattedData.ts";

import { IInfoNode } from "../../models/Graph/IInfoNode.ts";
import { IGraphLink } from "../../models/Graph/IGraphLink.ts";
import { generalLayoutNodes } from "../../helpers/flow/flow-diagram-layout.ts";
import { GraphDirection } from "../../helpers/flow/graph-direction.ts";
import { NodeType } from "../../models/Builder/NodeTypeEnum.ts";

interface CobeLink {
    _id: string;
    source: string;
    target: string;
}


export interface PipelineFlowViewerProps {
    isExpandable: boolean,
    formattedData: IFormattedData,
    openInLink: string,
    nodesDraggable?: boolean,
    anchorsConnectable?: boolean,
    onNodeClicked?: (event: MouseEvent, node: Node) => void,
    selectedNode?: Node | null
}

export const PipelineFlowViewer = (props: PipelineFlowViewerProps) => {


    const [nodes, setNodes] = useNodesState([]);
    const [edges, setEdges] = useEdgesState([]);

    const { id } = useParams();

    const graphDirection = GraphDirection.LEFT_TO_RIGHT

    const onNodesChange = useCallback(
        (changes: any) => setNodes((nds) => applyNodeChanges(changes, nds)),
        [],
    );
    const onEdgesChange = useCallback(
        (changes: any) => {
            setEdges((eds) => applyEdgeChanges(changes, eds))
        },
        [],
    );

    // Memoize the nodeTypes object to avoid recreating the object on each re-render
    const nodeTypes = useMemo(() => ( {
        baseNode: BaseNode,
        toolNode: ToolNode
    } ), []);

    const navigate = useNavigate();

    useEffect(() => {
        const newEdges = convertCobeLinksToFlowEdges(props.formattedData.graph.links);
        setEdges(newEdges);
        const newNodes = convertCobeNodesToFlowNodes(props.formattedData.graph.nodes);
        const layoutedNodes = generalLayoutNodes(newNodes, newEdges, { graphDirection, rankSeparationDistance: 120 });
        setNodes(layoutedNodes);


    }, []);

    // Whenever the selectedNode changes, update the nodes to reflect the change
    useEffect(() => {
        setNodes((nds) => {
            const newNodes = nds.map((node) => {
                const isSelected = props.selectedNode && node.id === props.selectedNode.id;
                return {
                    ...node,
                    data: {
                        ...node.data,
                        isSelected: isSelected
                    }
                }
            });
            return newNodes;
        });
    }, [props.selectedNode]);

    /**
     * Convert the links from the graph to the edges for the flow component.
     * Links have all the properties of an edge, but the _id property needs to be converted to id.
     *
     * @param links - The links from the graph
     */
    const convertCobeLinksToFlowEdges = (links: IGraphLink[]): Edge[] => {
        return links.map((link: IGraphLink) => ( {
            id: link._id,
            source: link.source,
            target: link.target,
            type: 'smoothstep',
            markerEnd: {
                type: MarkerType.Arrow,
                width: 30,
                height: 30
            }
        } ));
    }

    /**
     * Convert the nodes from the graph to the nodes for the flow component.
     * @param nodes - The nodes from the graph
     */
    const convertCobeNodesToFlowNodes = (nodes: IInfoNode[]): Node[] => {
        // For each node, create a new node object with the correct type and data
        const newNodes: Node[] = nodes.map((node: IInfoNode) => {
            if (node.nodetype === 'ToolNode') {
                const data: ToolNodeData = {
                    label: node.name,
                    externalLinkLabel: "",
                    anchorsConnectable: props.anchorsConnectable ? props.anchorsConnectable : false,
                    inProgress: false,
                    id: node.id,
                    _id: node._id,
                    nodeClickable: !!props.onNodeClicked,
                    isSelected: false,
                    // TODO: this needs to be set at some point
                    nodeType: NodeType.UNSET,
                }
                if (node.info.executableLinks && node.info.executableLinks.length > 0) {
                    const executableLink = node.info.executableLinks[0];
                    data.externalLinkLabel = executableLink.linkType;
                }
                return {
                    type: 'toolNode',
                    id: node.id,
                    position: { x: 0, y: 0 },
                    data
                }
            } else if (node.nodetype === 'BaseNode') {
                let externalLinkLabel = "";
                switch (node.type) {
                    case "datatype":
                        externalLinkLabel = "Data Type";
                        break;
                    case "origin":
                        externalLinkLabel = "Origin";
                        break;
                }
                return {
                    type: 'baseNode',
                    id: node.id,
                    position: { x: 0, y: 0 },
                    data: {
                        label: node.name,
                        externalLinkLabel,
                        _id: node._id,
                        id: node.id
                    }
                }
            }
            return {
                type: 'default',
                id: node.id,
                position: { x: 0, y: 0 },
                data: {}
            };
        });
        return newNodes;
    }

    const onViewClick = () => {
        const navState: PipelineExpandedDiagramLocationState = {
            formattedData: props.formattedData,
            openInLink: props.openInLink
        }
        navigate(
            `/pipeline/${ id }/expanded`,
            {
                state: navState
            })
    }

    const onNodeClicked = (event: any, node: any) => {
        if (props.onNodeClicked) {
            props.onNodeClicked(event, node);
        }
    }

    return (
        <div className="flex-grow h-full">
            <ReactFlow
                nodeTypes={ nodeTypes }
                nodes={ nodes }
                edges={ edges }
                onNodesChange={ props.nodesDraggable ? onNodesChange : undefined }
                onEdgesChange={ onEdgesChange }
                // onConnect={ onConnect }
                fitView={ true }
                proOptions={ { hideAttribution: true } }
                onNodeClick={ onNodeClicked }
            >
                <Controls showInteractive={false}/>
                <Background gap={ 12 } size={ 1 }/>
                {
                    props.isExpandable &&
                    <Panel position="bottom-right">
                        <div
                            className="h-8 px-3 py-2 bg-pipeline_diagram_view_button rounded shadow border border-pipeline_diagram_view_button justify-center items-center gap-2.5 inline-flex cursor-pointer">
                            <div className="text-center text-white text-sm font-semibold font-inter leading-none"
                                 onClick={ onViewClick }>View
                            </div>
                        </div>
                    </Panel>
                }
                {/*<MiniMap></MiniMap>*/ }

            </ReactFlow>
            <Outlet/>
        </div>
    );
};
