import { Handle, NodeProps, Position } from 'reactflow';
import { ReactSVG } from "react-svg";
import IMAGES from "../../../assets/images/images.tsx";
import React from "react";

type BaseNodeData = {
    label: string;
    externalLinkLabel: string;
    id: string;
    _id: string;
};

export const BaseNode = (props: NodeProps<BaseNodeData>) => {

    return (
        <div
            className="w-72 p-3 bg-white rounded-4xl shadow border border-gray-200 justify-start items-center gap-2 inline-flex">
            <div
                className={`${ props.data.externalLinkLabel === "Origin" ?  "bg-accent_green" : "bg-base_node_icon" } p-1.5 rounded-4xl border border-black border-opacity-20 justify-center items-center gap-2.5 flex` }>
                <div className="w-6 h-6 relative">
                    <div className="w-6 h-6 left-0 top-0 absolute ">
                        { props.data.externalLinkLabel === "Origin" ?
                            <ReactSVG src={ IMAGES.database_icon }/> :
                            <ReactSVG src={ IMAGES.bar_chart_icon }/>
                        }

                    </div>
                </div>
            </div>
            <div className="grow shrink basis-0 flex-col justify-start items-start gap-0.5 inline-flex">
                <div
                    className="w-full text-neutral-800 text-sm font-semibold font-inter leading-none">{ props.data.label }</div>
                <div
                    className="self-stretch text-neutral-500 text-xs font-normal font-inter leading-[18px]">{ props.data.externalLinkLabel }</div>
            </div>

            <Handle type="source" position={ Position.Right } id="output"/>
            <Handle type="target" position={ Position.Left } id="input"/>
        </div>
    );
};
