import {useCurrentPath} from "../../hooks/useCurrentPath.tsx";
import {ReactSVG} from "react-svg";
import {css} from "glamor";
import React, {useEffect, useRef} from "react";
import IMAGES from "../../assets/images/images.tsx";
import {useNavigate} from "react-router-dom";
import {useGetUserProfile} from "../../hooks/useGetUserProfile.ts";
import {Tooltip} from "primereact/tooltip";
import {Toast} from "primereact/toast";
import {useDispatch, useSelector} from "react-redux";
import {logout as logoutAction} from "../../store/slices/authSlice.ts";
import {IRootState} from "../../store/store.ts";
import {consoleWrap} from "../../main.tsx";
import { doRefreshToken } from "../../hooks/useRefreshToken.tsx";


const BLUE = '#2463BC';
const BLACK = '#000000';

const SEARCH_PATH = '/';
const BUILD_PATH = '/build';
const LEARN_PATH = '/learn';
const ADMIN_PATH = '/admin';
const PROFILE_PATH = '/profile';

const getStyles = (currentPath: string, path: string) => {
    return css({
        ' path': {
            fill: `${currentPath === path ? BLUE : BLACK}`,
        }
    });
}


export const VerticalNavBar = () => {
    const isLoggedIn = useSelector((state: IRootState) => state.auth.isLoggedIn)
    const path = useCurrentPath();
    const navigate = useNavigate();
    const {
        getUserProfile,
        isLoading: userProfileIsLoading,
        data: userProfileData,
        error: userProfileError} = useGetUserProfile();
    consoleWrap.log('path', path);

    useEffect(() => {
        if (isLoggedIn) {
            doRefreshToken().then(() => {
                getUserProfile();
            }).catch(e => {
                console.error("Failed to refresh token or fetch user profile", e);
            });
        }
    }, [isLoggedIn]);

    const dispatch = useDispatch();

    const toast = useRef<Toast>(null);

    const searchStyles = getStyles(path, SEARCH_PATH)

    const buildStyles = getStyles(path, BUILD_PATH)

    const learnStyles = getStyles(path, LEARN_PATH)

    const adminStyles = getStyles(path, ADMIN_PATH)

    const profileStyles = getStyles(path, PROFILE_PATH)

    const getUserProfileClick = () => {
        getUserProfile();
    }

    consoleWrap.log("userProfileData " + JSON.stringify(userProfileData?.user?.admin))

    const logout = () => {
        localStorage.removeItem('cobe-accessToken');
        toast.current?.show({severity: 'success', summary: 'Success', detail: 'Logged out successfully'});
        dispatch(logoutAction());
        navigate('/');
    }

    return (
        <div className="w-[88px] h-screen bg-white flex flex-col items-center py-5 border-r-2 border-r-gray-200">
            <Toast ref={toast}></Toast>
            {!isLoggedIn &&  <Tooltip target=".build-icon" style={{ boxShadow: 'none' }} />}

            <ul className="flex flex-col w-full h-full justify-between">
                <li className="flex flex-col items-center w-full mb-8">
                    <img src={IMAGES.cobe_logo} alt="Logo" />
                </li>
                <div className="flex flex-col items-center w-full mb-auto pt-5">
                    <li className="flex flex-col items-center w-full mb-5" onClick={() => navigate('/')}>
                        <div className={`mb-1 inline-flex justify-center items-center w-[56px] ${path === SEARCH_PATH ? 'bg-selected rounded-full' : 'hover:bg-selected hover:rounded-full'}`}>
                                <ReactSVG src={IMAGES.search_icon} {...searchStyles} />
                        </div>
                        <span className={`text-xs ${path === SEARCH_PATH ? 'text-interactive' : 'text-gray-700'}`}>Explore</span>
                    </li>
                    <li data-pr-tooltip="Please Login to use this feature"
                        data-pr-position="right"
                        data-pr-at="right center"
                        data-pr-my="left center"
                        onClick={() => navigate(BUILD_PATH)}
                        className="flex flex-col items-center w-full mb-5 build-icon">
                        <div
                            className={` mb-1 inline-flex justify-center items-center w-[56px] ${path === BUILD_PATH ? 'bg-selected rounded-full' : 'hover:bg-selected hover:rounded-full'}`}>
                            <ReactSVG src={IMAGES.build_icon}  {...buildStyles} />
                        </div>
                        <span className={`text-xs ${path === BUILD_PATH ? 'text-interactive' : 'text-gray-700'}`}>Build</span>
                    </li>
                    <li className="flex flex-col items-center w-full mb-5" onClick={() => navigate(LEARN_PATH)}>
                        <div className={`mb-1 inline-flex justify-center items-center w-[56px] ${path === LEARN_PATH ? 'bg-selected rounded-full' : 'hover:bg-selected hover:rounded-full'}`}>
                            <ReactSVG src={IMAGES.learn_icon} {...learnStyles} />
                        </div>
                        <span className={`text-xs ${path === LEARN_PATH ? 'text-interactive' : 'text-gray-700'}`}>Learn</span>
                    </li>
                    {isLoggedIn && userProfileData?.user?.admin && (
                        <li className="flex flex-col items-center w-full mb-5" onClick={() => navigate(ADMIN_PATH)}> {/* Add the admin icon */}
                            <div className={`mb-1 inline-flex justify-center items-center w-[56px] ${path === ADMIN_PATH ? 'bg-selected rounded-full' : 'hover:bg-selected hover:rounded-full'}`}>
                                <ReactSVG src={IMAGES.profile_icon} {...adminStyles} />  {/* Use the admin icon */}
                            </div>
                            <span className={`text-xs ${path === ADMIN_PATH ? 'text-interactive' : 'text-gray-700'}`}>Admin</span>
                        </li>
                    )}
                </div>
                {
                    isLoggedIn ? (
                        <>
                            <li data-testid="logoutBtn" className="flex flex-col items-center w-full mb-5" onClick={logout}>
                                <div className={`mb-1 inline-flex justify-center items-center w-[56px] hover:bg-selected hover:rounded-full`}>
                                    <ReactSVG src={IMAGES.logout_icon} {...profileStyles} />
                                </div>
                                <span className={`text-xs ${path === PROFILE_PATH ? 'text-interactive' : 'text-gray-700'}`}>Logout</span>
                            </li>
                            <li className="flex flex-col items-center w-full" onClick={getUserProfileClick}>
                                <div className={`mb-1 inline-flex justify-center items-center w-[56px] ${path === PROFILE_PATH ? 'bg-selected rounded-full' : 'hover:bg-selected hover:rounded-full'}`}>
                                    <ReactSVG src={IMAGES.profile_icon} {...profileStyles} />
                                </div>
                                <span className={`text-xs ${path === PROFILE_PATH ? 'text-interactive' : 'text-gray-700'}`}>Profile</span>
                            </li>
                        </>
                    ) : (
                        <li data-testid="loginBtn" className="flex flex-col items-center w-full" onClick={() => navigate('/login')}>
                            <div className={`mb-1 inline-flex justify-center items-center w-[56px] ${path === PROFILE_PATH ? 'bg-selected rounded-full' : 'hover:bg-selected hover:rounded-full'}`}>
                                <ReactSVG src={IMAGES.login_icon} {...profileStyles} />
                            </div>
                            <span className={`text-xs ${path === PROFILE_PATH ? 'text-interactive' : 'text-gray-700'}`}>Login</span>
                        </li>
                    )

                }

            </ul>
        </div>
    );
}

