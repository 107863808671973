import React, { FormEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import {
    BuilderPipelineSelectedNodeContext,
    IBuilderPipelineSelectedNodeContext
} from "../../../store/context/PipelineBuilder/builder-pipeline-selected-node-context";
import { ReactSVG } from "react-svg";
import IMAGES from "../../../assets/images/images.tsx";
import { AutoComplete, AutoCompleteCompleteEvent, AutoCompleteSelectEvent } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { FieldArray, Form, Formik } from 'formik';
import { FormikProps } from "formik/dist/types";
import { ErrorField } from "../../ErrorField/ErrorField.tsx";
import { RepeatableInputPanel } from "../PipelineInfo/RepeatableInputPanel.tsx";
import { RepeatableInputFormGroup } from "../PipelineInfo/RepeatableInputFormGroup.tsx";
import { ToolDocumentationFormInput } from "../PipelineInfo/ToolDocumentationFormInput.tsx";
import { RightPanelSaveButton } from "./RightPanelTemplate/RightPanelSaveButton.tsx";
import { updateSessionSearchTermState } from "../../../helpers/state/SearchState.ts";
import { useGetToolSearchSuggestions } from "../../../hooks/useGetToolSearchSuggestions.ts";
import useNodeService from "../../../hooks/pipeline-builder/useNodeService.ts"
import { BuilderFlowContext } from "../../../store/context/PipelineBuilder/builder-flow-context.ts";
import { useGetToolById } from "../../../hooks/useGetToolById.tsx";
import { consoleWrap } from "../../../main.tsx";
import {
    addToolToDraft,
    deleteNodes,
    DraftInstitution,
    GraphDraftToolNode,
    GraphDraftUnpersistedToolNode, GraphNodeInfoExecutableLink,
    IDraftGraphTool,
    upsertToolNodeIntoGraphDraft,
    upsertToolNodeIntoMainDraft
} from "../../../store/slices/Builder/BuilderSaveDraftState.ts";
import { useDispatch, useSelector } from "react-redux";
import {
    FoundToolNode,
    IGetNodeForToolData,
    useGetNodeForTool
} from "../../../hooks/pipeline-builder/useGetNodeForTool.ts";
import { Edge, Node } from "reactflow";
import { useSaveDraft } from "../../../hooks/pipeline-builder/useSaveDraft.ts";
import { RootState, store } from "../../../store/store.ts";
import { confirmDialog } from "primereact/confirmdialog";
import { Menu } from "primereact/menu";
import { ExecutableLinkFormInput } from "../PipelineInfo/ExecutableLinkFormInput.tsx";
import { IFormInitializationValue } from "../../../hooks/pipeline-builder/useInitializeForm.ts";

export interface IAddToolRightPanelForm {
    toolName: string;
    toolPurposeDropdown: string;
    inputFileTypeDropdown: string;
    outputFileTypeDropdown: string;
    version: string;
    executableLinks: GraphNodeInfoExecutableLink[];
    toolDocumentation?: string[];
    doi: string;
    labGroups: string[];
    label: string;
    byline: string;
}

const generateEmptyExecutableLink = () => {
    return { linkType: '', executableLink: '', _id: undefined }
}

interface FormErrors extends Partial<IAddToolRightPanelForm> {
    toolDocumentationArrayError?: string[]; // toolDocumentationArrayError should be an array of strings
}

export interface IAddToolRightPanelProps {
    institutionOptions: IFormInitializationValue[]
}

export const AddToolRightPanel = ({ institutionOptions }: IAddToolRightPanelProps) => {

    const [visible, setVisible] = useState(false);
    const [searchValue, setSearchValue] = useState(''); // value of search bar input state
    const [selectedId, setSelectedId] = useState('');
    const [items, setItems] = useState<string[]>([]); // list of items for autocomplete
    const [fetchToolData, setFetchToolData] = useState(false); // State to control fetching tool data
    const [selectedFromSearch, setSelectedFromSearch] = useState(false); //Check if tool was selected from search

    const { updateNode, setEdges, deleteNode } = useNodeService();
    const selectedNodeContext = useContext(BuilderPipelineSelectedNodeContext);
    const flowContext = useContext(BuilderFlowContext);
    const menu = useRef<Menu>(null);

    const readOnly = useSelector((state: RootState) => state.builderMeta.readOnly);

    const submitFormRef = useRef<FormikProps<IAddToolRightPanelForm>>(null);

    const dispatch = useDispatch();
    const {
        getToolSearchSuggestions,
        isLoading: searchSuggestionsLoading,
        data: searchSuggestionsResponse,
        error: searchSuggestionsError
    } = useGetToolSearchSuggestions();

    const {
        getGetToolById,
        isLoading: toolOptionsLoading,
        data: toolOptionsData,
        error: toolOptionsError
    } = useGetToolById();

    const {
        getNodeForTool,
        data: getNodeForToolData,
    } = useGetNodeForTool();

    const {
        postSaveDraft,
        isLoading: saveDraftIsLoading
    } = useSaveDraft();

    const [startSave, setStartSave] = useState(false);
    const [saveInProgress, setSaveInProgress] = useState(false)

    // Effect that triggers a call to the backend to get the node for the tool
    useEffect(() => {
        // If the save process has started, and it is not already in progress, and the form ref is available
        // This if is used to make sure the request only gets sent once.
        if (startSave && !saveInProgress && submitFormRef.current) {
            // Set save in progress to true to prevent multiple calls
            setSaveInProgress(true);
            const { toolName, version, toolPurposeDropdown } = submitFormRef.current.values;
            // Call the getNodeForTool function to get the node for the tool
            getNodeForTool(toolName, version, toolPurposeDropdown);
        }
        // Reset the startSave flag after the save process has started
        setStartSave(false);

    }, [startSave, saveInProgress]);

    // Effect that triggers a save call whenever the getNodeForToolData is updated
    useEffect(() => {
        // If the getNodeForToolData is available and the form ref is available
        if (getNodeForToolData && submitFormRef.current) {
            // Call the save function
            save(getNodeForToolData, submitFormRef.current.values);
        }
    }, [getNodeForToolData]);

    useEffect(() => {
        if (searchSuggestionsResponse) {
            const toolNamesArray = searchSuggestionsResponse.map((item: { name: string }) => item.name);
            setItems(toolNamesArray);
        }
    }, [searchSuggestionsResponse]);

    /**
     * Manages the behavior of the form based on the node selected within a context
     * useEffect for managing form visibility upon node selection or update
     */

    useEffect(() => {
        // Check if the selected node has data
        const nodeHasData = selectedNodeContext.selectedNode?.data?.toolData;

        // Set the form visibility based on whether the node has data
        setVisible(!!nodeHasData);

        if (nodeHasData) {
            // If the node has data, set the form values and search value from the node's data
            submitFormRef.current?.setValues(nodeHasData);
            setSearchValue(nodeHasData.toolName);

            // Call getToolSearchSuggestions to ensure the data is fetched
            getToolSearchSuggestions({ query: nodeHasData.toolName });
        } else {
            // If the node does not have data and it was not selected from search, reset the form and search value
            if (!selectedFromSearch) {
                submitFormRef.current?.resetForm();
                setSearchValue('');
            }
        }
        // Reset the selectedFromSearch flag
        setSelectedFromSearch(false);
    }, [selectedNodeContext.selectedNode]);

    useEffect(() => {
        // Check if the search suggestions response is available and the selected node has a label
        if (searchSuggestionsResponse) {
            // Find the tool in the search suggestions response that matches the selected node's label
            const foundTool = searchSuggestionsResponse.find(
                (item: { name: string }) => item.name === selectedNodeContext?.selectedNode?.data.label
            );

            if (foundTool) {
                // Check if the node is a saved node
                const isSavedNode = selectedNodeContext?.selectedNode?.data.label !== 'New Tool';
                if (isSavedNode) {
                    // If it is a saved node, set the state to fetch tool data and set the selected ID
                    setFetchToolData(true);
                    setSelectedId(foundTool?.id || ''); // Use foundTool.id if found, otherwise use an empty string
                }
            }
        }
    }, [searchSuggestionsResponse]);


    useEffect(() => {
        if (selectedId && fetchToolData) {
            getGetToolById({ id: selectedId, getAssociatedTools: true });
            setFetchToolData(false); // Reset flag after fetching tool data
        }
    }, [selectedId, fetchToolData]);

    const onToolSelect = useCallback(async (e: AutoCompleteSelectEvent) => {
        const selectedToolName = e.value;
        setSearchValue(selectedToolName); // Set value to selected tool name
        setVisible(true); // Set the form fields to be visible upon tool selection
        setSelectedFromSearch(true); // Indicate that a node is selected from search

        const foundTool = searchSuggestionsResponse.find((item: { name: string }) => item.name === selectedToolName);

        if (foundTool) {
            setSelectedId(foundTool.id);
            await getGetToolById({ id: foundTool.id, getAssociatedTools: true });

            if (submitFormRef.current) {
                await submitFormRef.current.setFieldValue('toolName', selectedToolName);

                // Clear tool purpose field
                await submitFormRef.current.setFieldValue('toolPurposeDropdown', '');
                await submitFormRef.current.setFieldValue('inputFileTypeDropdown', '');
                await submitFormRef.current.setFieldValue('outputFileTypeDropdown', '');
                const emptyExecLink = generateEmptyExecutableLink();
                await submitFormRef.current.setFieldValue('executableLinks', [emptyExecLink]);
                await submitFormRef.current.setFieldTouched('executableLinks', true, false);

            }
        }
    }, [getGetToolById, searchSuggestionsResponse, toolOptionsData]);


    const toolPurposeOptions = toolOptionsData?.purposes?.map((purpose: { text: any; }) => ( {
        label: purpose.text,
        value: purpose.text,
    } )) || [];

    const onSearchValueChange = (e: { value: string }) => {
        setSearchValue(e.value);
    };

    const searchSuggestions = (event: AutoCompleteCompleteEvent) => {
        getToolSearchSuggestions({ query: event.query });
    };

    const search = () => {
        updateSessionSearchTermState(searchValue);
    };

    const submitForm = (e: FormEvent<Element> | MouseEvent) => {
        e.preventDefault();
        if (submitFormRef.current) {
            submitFormRef.current.submitForm();
        }
    };

    const validate = (values: IAddToolRightPanelForm): FormErrors => {
        const errors: FormErrors = {};
        let isAnyFieldEmpty = false;

        if (!values.toolName) {
            errors.toolName = 'Tool Name is required';
        }
        if (!values.toolPurposeDropdown) {
            errors.toolPurposeDropdown = 'Tool Purpose is required';
        }
        if (!values.inputFileTypeDropdown) {
            errors.inputFileTypeDropdown = 'Input File Type is required';
        }
        if (!values.outputFileTypeDropdown) {
            errors.outputFileTypeDropdown = 'Output File Type is required';
        }
        if (values.executableLinks && values.executableLinks.length > 0) {
            const executableLinkErrors: any[] = values.executableLinks.map((link: GraphNodeInfoExecutableLink) => {
                const linkErrors: any = {};
                if (!link.executableLink || !link.linkType) {
                    if (!link.executableLink) {
                        linkErrors.link = "Executable Link is required";
                    }
                    if (!link.linkType) {
                        linkErrors.type = "Executable Type is required";
                    }
                }
                return linkErrors;
            });

            if (executableLinkErrors.some(error => Object.keys(error).length > 0)) {
                errors.executableLinks = executableLinkErrors;
            }
        }

        return errors;
    };

    /**
     * Formik submit handler.
     * @param values
     */
    const onFormikSubmit = (values: IAddToolRightPanelForm) => {
        consoleWrap.log("Formik Submitted: ", values);
        // Begin the process of saving the tool node to the draft state
        setStartSave(true);
        setVisible(true);
    }

    /**
     * Finds the selected node in the context.
     * @param selectedNode - The selected node
     * @param nodeList - The list of nodes in the flow context
     */
    const findSelectedNodeInContext = (selectedNode: Node | null, nodeList: Node[]): Node | undefined => {
        if (!selectedNode) {
            abortSave("No node selected")
            return;
        }
        const selectedNodeId = selectedNode.id;
        // Find the node in the flow context. We'll need to update things like its data and its ID.
        // We find the node in the context because it may not be in the state yet. The node is either new or being updated.
        // If its being updated, then it is already in the state, but if its new, then it hasnt been added to the state yet (that happens later in this function).
        const nodeToUpdate = nodeList.find(node => node.id === selectedNodeId);
        if (!nodeToUpdate) {
            abortSave("Could not find node in flow context.")
            return;
        }
        return nodeToUpdate;
    }

    /**
     * Aborts the save process.
     * @param message - The message to log
     */
    const abortSave = (message: string) => {
        consoleWrap.error("Could not find node in flow context.");
        setSaveInProgress(false);
    }

    const save = async (getNodeForToolResponse: IGetNodeForToolData, formikValues: IAddToolRightPanelForm) => {
        // The react-flow node that is selected in the context
        const nodeToUpdate = findSelectedNodeInContext(selectedNodeContext.selectedNode, flowContext.nodes);
        if (!nodeToUpdate) {
            abortSave("Could not find node in flow context.")
            return;
        }
        // Check if the response is valid
        if (!getNodeForToolResponse) {
            abortSave("Could not load node from backend.")
            return;
        }
        // Prepare the updated node object by copying everything, but fill in the data appropriately
        const updatedNode = {
            ...nodeToUpdate,
            id: getNodeForToolResponse.node.id,
            data: {
                ...nodeToUpdate.data,
                // Storing the tool data into the node, just in case its needed later
                toolData: formikValues,
                label: formikValues.toolName,  // Update the label
                byline: formikValues.executableLinks[0] ? formikValues.executableLinks[0].linkType : ''
            }
        };

        // Get edges that are connected to nodeToUpdate and change the ID to match the new ID assigned in updatedNode
        const updatedEdges = flowContext.edges.map(edge => {
            if (edge.target === nodeToUpdate.id) {
                edge.target = getNodeForToolResponse.node.id;
            }

            if (edge.source === nodeToUpdate.id) {
                edge.source = getNodeForToolResponse.node.id;
            }

            return edge;
        });

        // Update the node and edges in the context
        updateNode(nodeToUpdate.id, updatedNode);
        setEdges(updatedEdges);
        setSelectedId(updatedNode.id);

        // Begin process of saving the node to the node lists in the draft state.
        saveToDraftState(
            nodeToUpdate.id,
            getNodeForToolResponse.node,
            getNodeForToolResponse.tool,
            formikValues,
            updatedEdges);

        const currDraftState = store.getState().builderSaveDraft;
        await postSaveDraft(currDraftState);
        setSaveInProgress(false);
    }

    /**
     * Saves the tool node to the draft state.
     * @param oldNodeId - The old node ID
     * @param foundToolNode - The found tool node (the node property from useGetNodeForTool response)
     * @param foundTool - The found tool (the tool property from useGetNodeForTool response)
     * @param formikValues - The formik values containing user inputted form data
     * @param updatedEdges - The updated React flow edges
     */
    const saveToDraftState = (oldNodeId: string, foundToolNode: FoundToolNode, foundTool: IDraftGraphTool, formikValues: IAddToolRightPanelForm, updatedEdges: Edge[]) => {

        // Add the tool node to the node list within the main draft state
        dispatch(
            upsertToolNodeIntoMainDraft({
                oldNodeId,
                node: {
                    ...
                        foundToolNode,
                    input:
                        [formikValues.inputFileTypeDropdown],
                    output:
                        [formikValues.outputFileTypeDropdown]
                }
            })
        );

        // Create a new tool node object with only the necessary properties
        const graphToolNode: GraphDraftToolNode | GraphDraftUnpersistedToolNode = {
            ...foundToolNode,
            info: {
                version: formikValues.version,
                purpose: formikValues.toolPurposeDropdown,
                executableLinks: formikValues.executableLinks,
                doi: formikValues.doi,
                link: formikValues.toolDocumentation ? formikValues.toolDocumentation : [],
                input: [formikValues.inputFileTypeDropdown],
                output: [formikValues.outputFileTypeDropdown],
                keywords: foundTool.keywords,
                score: foundTool.score,
                institution: formikValues.labGroups.map((labGroup): DraftInstitution => ( {
                    name: labGroup,
                    link: ''
                } ))
            }
        }

        // Find the edge that connects to this node
        const edge = updatedEdges.find(edge => edge.target === foundToolNode.id);
        if (!edge) {
            abortSave("Could not find edge for node. How is this possible?")
            return;
        }

        // Add the tool node to the node list within the graph draft state
        dispatch(
            upsertToolNodeIntoGraphDraft({
                oldNodeId,
                node: graphToolNode,
                link: { source: edge.source, target: edge.target }
            })
        );

        // Add the tool to the list of tools in the draft state.
        dispatch(
            addToolToDraft(foundTool)
        );

    }

    const deleteToolNode = async () => {
        // Find the node to be deleted in the context
        if (selectedNodeContext?.selectedNode?.id) {
            const nodeId = selectedNodeContext.selectedNode.id;
            const deletedNodeIds = deleteNode(nodeId);
            dispatch(deleteNodes({ idsToDelete: deletedNodeIds }));
            consoleWrap.log('Node deleted:', nodeId);
        }

        const currDraftState = store.getState().builderSaveDraft;
        const newPayload = {
            ...currDraftState,
            deletedNodeIds: [selectedId]
        }
        await postSaveDraft(newPayload);
    };

    const handleDeleteTool = () => {
        confirmDialog({
            message: 'Are you sure you want to delete this? This action cannot be undone',
            header: 'Delete?',
            defaultFocus: 'accept',
            acceptLabel: 'Yes, delete node',
            rejectLabel: 'Cancel',
            closable: false,
            accept: () => {
                deleteToolNode();
            },
            reject: () => { }
        });
    }

    const itemsDeleteNodeButton = [
        {
            template: (item: any, options: { className: any; onClick: React.MouseEventHandler<HTMLButtonElement> | undefined; }) => {
                return (
                    <Button
                        type="button"
                        className={`${options.className} w-40 h-7 px-2 py-1 rounded justify-center items-center gap-1 inline-flex `}
                        onClick={options.onClick}
                    >
                        <div className="w-3 h-6 relative">
                            <ReactSVG src={ IMAGES.backspace_icon }/>
                        </div>
                        <div className="grow shrink basis-0 text-neutral-800 text-sm font-normal font-['Inter'] leading-tight">
                            Delete node
                        </div>
                    </Button>
                );
            },
            command: () => {
                handleDeleteTool();
            }
        }
    ];

    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        // Check if Enter key is pressed
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent the default form submission
            // Check if there's a search value before making the form visible
            if (searchValue) {
                setVisible(true);
            }
        }
    };

    const renderSearchBar = () => (
        <div
            className="w-full h-28 py-2 px-0.5 border-b border-gray-200 flex-col justify-start items-start gap-2 inline-flex mb-4">
            <div className="self-stretch text-neutral-500 text-sm font-normal font-['Inter'] leading-tight">
                Search for an existing tool.
            </div>
            <div className="relative w-full">
                <Button
                    className="absolute inset-y-0 left-0"
                    text
                    pt={ { root: { className: 'hover:bg-inherit z-10' } } }
                    onClick={ search }
                >
                    <ReactSVG src={ IMAGES.search_icon } className="w-5 h-6 text-gray-600"/>
                </Button>
                <AutoComplete
                    className="w-full bg-white"
                    value={ searchValue }
                    suggestions={ items }
                    completeMethod={ searchSuggestions }
                    minLength={ 2 }
                    onSelect={ onToolSelect }
                    onChange={ onSearchValueChange }
                    onKeyDown={ onKeyDown }
                />
            </div>
        </div>
    );

    const getInitialValues = (selectedNodeContext: IBuilderPipelineSelectedNodeContext) => {
        const emptyExecLink = generateEmptyExecutableLink()
        return selectedNodeContext.selectedNode && selectedNodeContext.selectedNode.data.toolData
            ? {
                ...selectedNodeContext.selectedNode.data.toolData,
                toolDocumentation: selectedNodeContext.selectedNode.data.toolData.toolDocumentation || [""],
            }
            : {
                toolName: "",
                toolPurposeDropdown: "",
                inputFileTypeDropdown: "",
                outputFileTypeDropdown: "",
                version: "",
                executableLinks: [emptyExecLink],
                toolDocumentation: [""],
                doi: "",
                labGroups: [""],
            };
    };

    const initialValues = useMemo(() => getInitialValues(selectedNodeContext), [selectedNodeContext]);

    return (
        <div
            className="flex self-stretch grow shrink basis-0 flex-col justify-start items-start overflow-y-scroll overflow-x-clip">
            <div className="self-stretch h-14 py-2 bg-white border-b border-gray-200 flex-col gap-2 flex">
                <div className="w-full px-5 h-10 justify-between gap-2 flex flex-row">
                    <div className="flex flex-row gap-2 items-center">
                        <div className="w-6 h-6">
                            <ReactSVG src={IMAGES.tool_icon_lg} />
                        </div>
                        <div className="grow">
                            <div className="self-stretch text-neutral-800 text-xl font-semibold font-inter leading-normal">
                                Add Tool
                            </div>
                        </div>
                    </div>
                    <div className="relative p-2 shrink rounded-full cursor-pointer flex items-center justify-center">
                        <Button
                            onClick={(event) => menu.current?.toggle(event)}
                            className="p-2 rounded-full"
                            hidden={ readOnly }
                        >
                            <ReactSVG src={IMAGES.more_horizontal_icon} />
                        </Button>
                        <Menu
                            model={itemsDeleteNodeButton}
                            popup
                            ref={menu}
                            className="w-[168px] h-9 p-1 bg-white rounded-md shadow flex-col justify-start items-start gap-1 inline-flex border border-gray-300"
                        />
                    </div>
                </div>
            </div>
            <div className="overflow-y-auto flex-grow">
                <div className="px-5 py-4">
                    { /* As long as its not read only, then render the search bar */ }
                    { !readOnly && renderSearchBar() }
                    { (
                        <Formik
                            initialValues={ initialValues }
                            validate={ validate }
                            onSubmit={ onFormikSubmit }
                            innerRef={ submitFormRef }
                        >
                            { formik => {
                                // Now errors is accessible via formik.errors
                                const { errors, touched, getFieldProps } = formik;

                                const handlePurposeChange = (e: { value: any }) => {
                                    const selectedPurposeText = e.value;
                                    formik.setFieldValue('toolPurposeDropdown', selectedPurposeText);

                                    const selectedPurpose = toolOptionsData?.purposes.find(
                                        (p: { text: any }) => p.text === selectedPurposeText
                                    );

                                    if (selectedPurpose) {
                                        // Set executable links
                                        if (selectedPurpose.executableLinks) {
                                            formik.setFieldValue('executableLinks', selectedPurpose.executableLinks);
                                        } else {
                                            const emptyExecLink = generateEmptyExecutableLink();
                                            formik.setFieldValue('executableLinks', [emptyExecLink]);
                                        }
                                    }

                                    const toolDocumentation = toolOptionsData?.link ? toolOptionsData.link : [''];
                                    const toolDoi = toolOptionsData?.doi || '';
                                    const toolVersion = toolOptionsData?.versions ? toolOptionsData.versions[0] : '';

                                    submitFormRef?.current?.setFieldValue('toolDocumentation', toolDocumentation);
                                    submitFormRef?.current?.setFieldValue('doi', toolDoi);
                                    submitFormRef?.current?.setFieldValue('version', toolVersion);

                                };
                                return (
                                    <Form>
                                        { visible && (
                                            <div className="flex flex-grow flex-col gap-4 overflow-y-auto">

                                                <div className="h-auto gap-1">
                                                    <div className="w-full flex items-start gap-0.5">
                                                        <div
                                                            className="text-black-900 text-sm font-inter leading-tight">Tool
                                                            Name
                                                        </div>
                                                        <div
                                                            className="text-red-500 text-sm font-normal font-inter leading-tight pl-0.5">*
                                                        </div>
                                                    </div>

                                                    <InputText
                                                        { ...formik.getFieldProps('toolName') ?? '' }
                                                        id="toolName"
                                                        disabled={ readOnly }
                                                        readOnly={ selectedFromSearch }
                                                        className={ `p-inputtext-sm w-full ${ errors.toolName && formik.touched.toolName ? "border-red-500" : "border-gray-300" }` }
                                                    />
                                                    { errors.toolName && formik.submitCount > 0 && (
                                                        <ErrorField
                                                            errorMessage={ errors.toolName }
                                                        />
                                                    ) }
                                                </div>

                                                <div className="h-auto flex flex-col gap-1">
                                                    <div className="w-full flex items-start gap-0.5">
                                                        <div
                                                            className="text-black-900 text-sm font-inter leading-tight">Tool
                                                            Purpose
                                                        </div>
                                                        <div
                                                            className="text-red-500 text-sm font-normal font-inter leading-tight pl-0.5">*
                                                        </div>
                                                    </div>
                                                    <Dropdown
                                                        name="toolPurposeDropdown"
                                                        options={ toolPurposeOptions ?? '' }
                                                        value={ formik.values.toolPurposeDropdown ?? '' }
                                                        onChange={ handlePurposeChange }
                                                        placeholder="Select Tool Purpose"
                                                        className={ `p-inputtext-sm w-full ${ formik.errors.toolPurposeDropdown && formik.submitCount > 0 ? "!border-red-500" : "border-zinc-400" }` }
                                                        editable
                                                        disabled={ readOnly }
                                                    />
                                                    { errors.toolPurposeDropdown && formik.submitCount > 0 && (
                                                        <ErrorField
                                                            errorMessage={ errors.toolPurposeDropdown }
                                                        />
                                                    ) }
                                                </div>

                                                <div className="h-auto flex flex-col gap-1">
                                                    {/* Tool Purpose Dropdown */ }
                                                    <div className="w-full flex items-start gap-0.5">
                                                        <div
                                                            className="text-black-900 text-sm font-inter leading-tight">Input
                                                            File Type
                                                        </div>
                                                        <div
                                                            className="text-red-500 text-sm font-normal font-inter leading-tight pl-0.5">*
                                                        </div>
                                                    </div>
                                                    <Dropdown
                                                        { ...formik.getFieldProps('inputFileTypeDropdown') ?? [''] }
                                                        options={ toolOptionsData?.input ?? [''] }
                                                        placeholder="Select Input File Type"
                                                        className={ `p-inputtext-sm w-full ${ errors.inputFileTypeDropdown && formik.submitCount > 0 ? "!border-red-500" : "border-zinc-400" }` }
                                                        editable
                                                        disabled={ readOnly }
                                                    />
                                                    { errors.inputFileTypeDropdown && formik.submitCount > 0 && (
                                                        <ErrorField
                                                            errorMessage={ errors.inputFileTypeDropdown }
                                                        />
                                                    ) }
                                                </div>
                                                <div className="h-auto flex flex-col gap-1">
                                                    {/* Tool Purpose Dropdown */ }
                                                    <div className="w-full flex items-start gap-0.5">
                                                        <div
                                                            className="text-black-900 text-sm font-inter leading-tight">Output
                                                            File Type
                                                        </div>
                                                        <div
                                                            className="text-red-500 text-sm font-normal font-inter leading-tight pl-0.5">*
                                                        </div>
                                                    </div>
                                                    <Dropdown
                                                        { ...formik.getFieldProps('outputFileTypeDropdown') ?? [''] }
                                                        options={ toolOptionsData?.output ?? [''] }
                                                        placeholder="Select Output File Type"
                                                        className={ `p-inputtext-sm w-full ${ errors.outputFileTypeDropdown && formik.submitCount > 0 ? "!border-red-500" : "border-zinc-400" }` }
                                                        editable
                                                        disabled={ readOnly }
                                                    />
                                                    { errors.outputFileTypeDropdown && formik.submitCount > 0 && (
                                                        <ErrorField
                                                            errorMessage={ errors.outputFileTypeDropdown }
                                                        />
                                                    ) }
                                                </div>
                                                <div className="h-auto flex flex-col gap-1">
                                                    <label htmlFor="version"
                                                           className="text-sm">Version</label>
                                                    { toolOptionsData?.versions.length === 1 ? (
                                                        <InputText
                                                            { ...formik.getFieldProps('version') }
                                                            placeholder={ toolOptionsData?.versions[0] || '' }
                                                            id="version"
                                                            className={ `p-inputtext-sm w-full ${ formik.touched.version && formik.errors.version ? 'border-red-500' : 'border-gray-300' }` }
                                                            disabled={ readOnly }
                                                        />
                                                    ) : (
                                                        <Dropdown
                                                            { ...formik.getFieldProps('version') }
                                                            options={ toolOptionsData?.versions.map((version: string) => ( {
                                                                label: version,
                                                                value: version
                                                            } )) }
                                                            placeholder="Select Version"
                                                            className={ `p-inputtext-sm w-full ${ formik.touched.version && formik.errors.version ? 'border-red-500' : 'border-gray-300' }` }
                                                            disabled={ readOnly }
                                                        />
                                                    ) }
                                                    { formik.touched.version && formik.errors.version && (
                                                        <ErrorField errorMessage={ formik.errors.version }/>
                                                    ) }
                                                </div>
                                                <RepeatableInputPanel
                                                    name={"executables"}
                                                    dataTestId={"executablesAddOptionBtn"}
                                                    headerLabel={ "Executables" }
                                                    onAddAnotherOption={ () => {
                                                        const emptyExecLink = generateEmptyExecutableLink();
                                                        formik.setFieldValue('executableLinks', [...formik.values.executableLinks, emptyExecLink])
                                                    } }
                                                    readOnly={ readOnly }
                                                >
                                                    <FieldArray name={ "executableLinks" }>

                                                        { ({ remove }) => {
                                                            return (
                                                                formik.values.executableLinks.map((val: GraphNodeInfoExecutableLink, index: number) => {
                                                                    return (
                                                                        <div key={`ExecutableLinkFormInput-${index}`}>
                                                                            <ExecutableLinkFormInput
                                                                                name="executableLinks"
                                                                                placeholder="Link to Executable"
                                                                                onDelete={remove}
                                                                                index={index}
                                                                                readOnly={ readOnly }
                                                                            />
                                                                        </div>
                                                                    );
                                                                })
                                                            );
                                                        } }
                                                    </FieldArray>

                                                </RepeatableInputPanel>

                                                <div>
                                                    <RepeatableInputPanel
                                                        name={ "toolDocumentation" }
                                                        headerLabel={ "Tool Documentation" }
                                                        onAddAnotherOption={ () => {
                                                            formik.setFieldValue('toolDocumentation', [...( formik.values.toolDocumentation || [] ), '']);
                                                        } }
                                                        readOnly={ readOnly }
                                                    >
                                                        <FieldArray name={ "toolDocumentation" }>
                                                            { ({ remove }) => (
                                                                formik.values.toolDocumentation?.map((val: any, index: number) => (
                                                                    <ToolDocumentationFormInput
                                                                        key={ index }
                                                                        onDelete={ () => remove(index) }
                                                                        name={ `toolDocumentation` }
                                                                        index={ index }
                                                                        readOnly={ readOnly }
                                                                    />
                                                                ))
                                                            ) }
                                                        </FieldArray>

                                                    </RepeatableInputPanel>
                                                </div>

                                                <div className="h-auto flex flex-col gap-1">
                                                    <label htmlFor="doi" className="text-sm">DOI</label>
                                                    <InputText
                                                        id="doi"
                                                        { ...formik.getFieldProps('doi') }
                                                        className={ `p-inputtext-sm w-full ${ formik.touched.doi && formik.errors.doi ? 'border-red-500' : 'border-gray-300' }` }
                                                        disabled={ readOnly }
                                                    />
                                                    { formik.touched.doi && formik.errors.doi && (
                                                        <ErrorField errorMessage={ formik.errors.doi }/>
                                                    ) }
                                                </div>

                                                <RepeatableInputPanel
                                                    name={ "labGroups" }
                                                    headerLabel={ "Lab Groups/Institution" }
                                                    onAddAnotherOption={ () => {
                                                        formik.setFieldValue('labGroups', [...formik.values.labGroups, ''])
                                                    } }
                                                    readOnly={ readOnly }
                                                >
                                                    <FieldArray name={ "labGroups" }>
                                                        { ({ remove, form }) => {
                                                            // Filter out the options that have already been selected
                                                            const filteredOptions = institutionOptions.filter((option) => !form.values.labGroups.includes(option.value) );
                                                            return (
                                                                formik.values.labGroups.map((val: any, index: number) => {
                                                                    return (
                                                                        <RepeatableInputFormGroup
                                                                            key={ `LabGroupFormInput-${ index }` }
                                                                            onDelete={ () => remove(index) }
                                                                            name="labGroups"
                                                                            text="Lab Group/Institution"
                                                                            index={ index }
                                                                            dropdownOptions={ filteredOptions }
                                                                            readOnly={ readOnly }
                                                                        />
                                                                    );
                                                                })
                                                            );
                                                        } }
                                                    </FieldArray>
                                                </RepeatableInputPanel>

                                            </div>
                                        ) }
                                    </Form>
                                );
                            } }
                        </Formik>
                    ) }
                </div>
            </div>
            { !readOnly && visible && (
                <div className="w-full h-[72px]">
                    <RightPanelSaveButton onClick={ submitForm }/>
                </div>
            ) }
        </div>
    );
};
