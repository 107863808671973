import React, {useEffect, useRef, useState} from 'react';
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {FieldArray, Form, Formik, FormikHelpers, FormikProps} from "formik";
import {Toast} from 'primereact/toast';
import {InputText} from 'primereact/inputtext';
import {RepeatableInputPanel} from "../PipelineInfo/RepeatableInputPanel";
import {ModalFieldLabel} from "../../Modal/ModalFieldLabel.tsx";
import {ErrorField} from "../../ErrorField/ErrorField";
import {useEditTool} from "../../../hooks/useEditTool";
import {IToolTableDataResponse} from "../../../hooks/useGetToolTableData.tsx";
import {SelectItemOptionsType} from "primereact/selectitem";
import {ToolDocumentationFormInput} from "../PipelineInfo/ToolDocumentationFormInput.tsx";
import {RepeatableInputFormGroup} from "../PipelineInfo/RepeatableInputFormGroup.tsx";
import {ExecutableLinkFormInput} from "../PipelineInfo/ExecutableLinkFormInput.tsx";
import {useGetToolById} from "../../../hooks/useGetToolById.tsx";
import {useGetUserProfile} from "../../../hooks/useGetUserProfile.ts";
import {consoleWrap} from "../../../main.tsx";
import {useGetToolUsers} from "../../../hooks/useGetToolUsers.ts";
import {doRefreshToken} from "../../../hooks/useRefreshToken.tsx";
import { ApiWrapper } from "../../ApiWrapper/ApiWrapper.tsx";

interface IEditToolModalProps {
    onHide: () => void;
    visible: boolean;
    institutionOptions: SelectItemOptionsType;
    toolData: IToolTableDataResponse | null;
    onSuccess?: (updatedTool: any) => void;
}

interface FormValues {
    toolName: string;
    toolPurpose: string;
    inputFileTypeDropdown: string;
    outputFileTypeDropdown: string;
    version: string;
    executableLinks: { executableLink: string; linkType: string }[];
    toolDocumentation?: string[];
    doi: string;
    institution: string[];
}

export function EditToolModal(props: IEditToolModalProps) {
    // Refs
    const formRef = useRef<FormikProps<FormValues>>(null);
    const toast = useRef<Toast>(null);

    // State
    const [email, setEmail] = useState<string>("");

    // Custom Hooks
    const { editTool, isLoading, error, data: editToolData } = useEditTool();
    const { getGetToolById, data: detailedToolData, error: detailedToolError, isLoading: detailedToolLoading } = useGetToolById(); // Use the hook
    const { getUserProfile, data: userProfile, error: userProfileError, isLoading: userProfileLoading } = useGetUserProfile();
    const { getToolUsers, isLoading: toolUsersLoading, data: toolUsers, error: toolUsersError } = useGetToolUsers();

    // Fetch tool data when modal is triggered and the toolData is passed in
    useEffect(() => {
        if (props.toolData && props.visible) {
            doRefreshToken().then(() => {
                if (props.toolData) {
                    getUserProfile();
                    getToolUsers();
                    if (props.toolData.id) {
                        getGetToolById({ id: props.toolData.id, getAssociatedTools: false })
                    }
                }
            });
        }
    }, [props.toolData, props.visible]);

    useEffect(() => {
        // Set email if available
        if (userProfile) {
            setEmail(userProfile.user.email);
        }
    }, [userProfile]);

    // Set form values once detailed tool data is fetched
    useEffect(() => {
        if (detailedToolData) {

            formRef.current?.resetForm();  // Ensure form is reset before setting new values

            let institutionNames: string[] = [];

            // Check if user field is an array and not empty
            if (Array.isArray(detailedToolData.user) && detailedToolData.user.length > 0) {
                institutionNames = detailedToolData.user.map((user: { _id: string; name: string }) => user.name);
            } else {
                institutionNames = [""];
            }

            formRef.current?.setValues({
                toolName: detailedToolData.name || "",
                toolPurpose: detailedToolData.purposes?.[0]?.text || "",
                inputFileTypeDropdown: detailedToolData.input?.[0] || "",
                outputFileTypeDropdown: detailedToolData.output?.[0] || "",
                version: detailedToolData.versions?.[0] || "",
                executableLinks: detailedToolData.purposes?.[0]?.executableLinks.map((link: { executableLink: any; linkType: any; }) => ({
                    executableLink: link.executableLink,
                    linkType: link.linkType,
                })) || [],
                toolDocumentation: detailedToolData.link?.length > 0 ? detailedToolData.link : [""],
                doi: detailedToolData.doi || "",
                institution: institutionNames
            });
        }
    }, [detailedToolData]);

    const validateForm = (values: FormValues) => {
        const errors: Partial<FormValues> = {};

        if (!values.toolName) {
            errors.toolName = 'Tool Name is required';
        }
        if (!values.toolPurpose) {
            errors.toolPurpose = 'Tool Purpose is required';
        }
        if (values.executableLinks && values.executableLinks.length > 0) {
            const executableLinkErrors: any[] = values.executableLinks.map((link: any) => {
                const linkErrors: any = {};
                if (!link.executableLink || !link.linkType) {
                    if (!link.executableLink) {
                        linkErrors.executableLink = "Executable Link is required";
                    }
                    if (!link.linkType) {
                        linkErrors.linkType = "Executable Type is required";
                    }
                }
                return linkErrors;
            });

            if (executableLinkErrors.some(error => Object.keys(error).length > 0)) {
                errors.executableLinks = executableLinkErrors;
            }
        }
        if (!values.toolDocumentation || values.toolDocumentation.some(doc => !doc)) {
            errors.toolDocumentation = ['Tool Documentation is required'];
        }
        return errors;
    };

    // Handle form submission
    const handleFormSubmission = async (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
        try {
            // Map institution names to their respective ObjectId if they exist
            const institutionIds = values.institution
                .map(institutionName => {
                    const user = toolUsers.find(tu => tu.name === institutionName);
                    return user ? user._id : null;
                })
                .filter(id => id !== null); // Remove any null values

            const toolData = {
                tool: {
                    _id: props.toolData?.id,
                    name: values.toolName,
                    versions: [values.version],
                    input: [values.inputFileTypeDropdown],
                    output: [values.outputFileTypeDropdown],
                    link: values.toolDocumentation?.filter(doc => typeof doc === 'string' && doc.trim() !== '') || [],
                    doi: values.doi,
                    user: institutionIds as string[], // Ensure this is correctly included
                    purposes: [
                        {
                            text: values.toolPurpose || "Default purpose",
                            executableLinks: values.executableLinks.map(link => ({
                                executableLink: link.executableLink,
                                linkType: link.linkType,
                                default: false,
                            })),
                        },
                    ],
                },
                email: email,  // Pass the email from the profile
            };

            consoleWrap.log("Submitting tool data:", toolData);

            editTool(toolData); // Await the updated tool from editTool

        } catch (error) {
            consoleWrap.error("Error updating tool:", error);
            formikHelpers.setSubmitting(false);
            toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Failed to update tool.' });
        }
    };

    // When the tool is updated and the editToolData is available, call the onSuccess callback and close the modal
    useEffect(() => {
        consoleWrap.log(editToolData)
        if (editToolData) {
            props.onSuccess?.(editToolData);
            props.onHide();  // Close modal after success
        }
    }, [editToolData]);

    // Show error toast if there is an error
    useEffect(() => {

        if (error) {
            consoleWrap.error('Failed to edit tool:', error);
            if (error.response?.status === 422 && formRef.current) {
                formRef.current.setErrors({
                    toolName: 'This tool name matches an existing tool in our database. Please choose another name.'
                });
            } else {
                toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Failed to update tool.' });
            }
        }
    }, [error]);


    const handleCancel = () => {
        props.onHide();
    };

    const dialogFooter = (
        <div className="w-full h-full bg-white justify-end items-center gap-3 inline-flex">
            <Button
                className="px-3 !py-2 !bg-white !text-gray-800 font-inter rounded shadow border border-zinc-400 justify-center items-center gap-2.5 flex"
                onClick={handleCancel}>
                Cancel
            </Button>
            <Button
                className="!py-2 !bg-blue-700 text-white font-inter rounded shadow border border-blue-700 justify-center items-center gap-2.5 flex"
                onClick={() => formRef.current?.submitForm()}
            >
                Save changes
            </Button>
        </div>
    );

    return (
        <div>
            <Toast ref={toast} />
            {props.visible && (
                <Dialog
                    header="Edit Tool"
                    visible={true}
                    onHide={props.onHide}
                    closable={false}
                    footer={dialogFooter}
                    className="w-[50vw]"
                    pt={{
                        header: { className: "border-b border-t border-gray-200" },
                        content: { className: "max-h-[75vh] !overflow-y-scroll" },
                        footer: { className: "border-t border-b border-gray-200 !py-4" }
                    }}
                >
                    {/* API wrapper for 3 different endpoints that must all be queried before the user can interact with the form. */}
                    <ApiWrapper
                        isLoading={ detailedToolLoading && toolUsersLoading && userProfileLoading }
                        error={ detailedToolError && toolUsersError && userProfileError }
                        data={ detailedToolData && toolUsers && userProfile }
                        loadingLabel={ "Loading tool..." }
                        errorLabel={ "Could not load the tool." } >

                        <Formik<FormValues>
                            initialValues={{
                                toolName: "",
                                toolPurpose: "",
                                inputFileTypeDropdown: "",
                                outputFileTypeDropdown: "",
                                version: "",
                                executableLinks: [],
                                toolDocumentation: [""],
                                doi: "",
                                institution: [""],
                            }}
                            onSubmit={handleFormSubmission}
                            validate={validateForm}
                            innerRef={formRef}
                        >
                            {formik => (
                                <Form
                                    onKeyDown={(e: { key: string; preventDefault: () => any; }) => e.key === 'Enter' && e.preventDefault()}
                                    onSubmit={formik.handleSubmit}>
                                    <div className="w-full flex-col justify-start gap-4 inline-flex items-stretch pt-5">
                                        { /* Name Field */}
                                        <div className="flex-col justify-start items-start gap-1 flex">
                                            <ModalFieldLabel label={"Tool Name"} required={true} />
                                            <InputText
                                                data-testid={'toolName'}
                                                placeholder="Tool Name"
                                                {...formik.getFieldProps("toolName")}
                                                className={`self-stretch px-3 py-2.5 bg-white rounded border justify-start items-center gap-2.5 inline-flex ${formik.submitCount > 0 && formik.errors.toolName ? "border-red-500" : "border-zinc-400"}`}
                                                pt={{
                                                    root: {
                                                        className: "!text-gray-800"
                                                    }
                                                }}
                                            />
                                            {formik.errors.toolName && formik.submitCount > 0 && (
                                                <ErrorField errorMessage={formik.errors.toolName} />
                                            )}
                                        </div>

                                        { /* Purpose Field */}
                                        <div className="flex-col justify-start items-start gap-1 flex">
                                            <ModalFieldLabel label={"Purpose"} required={true} />
                                            <InputText
                                                data-testid={'purposeName'}
                                                placeholder="Purpose of the tool"
                                                {...formik.getFieldProps("toolPurpose")}
                                                className={`self-stretch px-3 py-2.5 bg-white rounded ${
                                                    formik.submitCount > 0 && formik.errors.toolPurpose ? "border-red-500" : "border-zinc-400"
                                                } justify-start items-center gap-2.5 inline-flex`}
                                                pt={{
                                                    root: {
                                                        className: "!text-gray-800"
                                                    }
                                                }}
                                            />
                                            {formik.errors.toolPurpose && formik.submitCount > 0 && (
                                                <ErrorField errorMessage={formik.errors.toolPurpose} />
                                            )}
                                        </div>

                                        { /* Input File Type Field */}
                                        <div className="flex-col justify-start items-start gap-1 flex">
                                            <ModalFieldLabel label={"Input File Type"} />
                                            <InputText
                                                data-testid={'inputFileTypeDropdown'}
                                                placeholder="Input File Type"
                                                {...formik.getFieldProps("inputFileTypeDropdown")}
                                                className="self-stretch px-3 py-2.5 bg-white rounded border border-zinc-400 justify-start items-center gap-2.5 inline-flex"
                                                pt={{
                                                    root: {
                                                        className: "!text-gray-800"
                                                    }
                                                }}
                                            />
                                            {formik.errors.inputFileTypeDropdown && formik.submitCount > 0 && (
                                                <ErrorField errorMessage={formik.errors.inputFileTypeDropdown} />
                                            )}
                                        </div>

                                        { /* Output File Type Field */}
                                        <div className="flex-col justify-start items-start gap-1 flex">
                                            <ModalFieldLabel label={"Output File Type"} />
                                            <InputText
                                                data-testid={'outputFileTypeDropdown'}
                                                placeholder="Output File Type"
                                                {...formik.getFieldProps("outputFileTypeDropdown")}
                                                className="self-stretch px-3 py-2.5 bg-white rounded border border-zinc-400 justify-start items-center gap-2.5 inline-flex"
                                                pt={{
                                                    root: {
                                                        className: "!text-gray-800"
                                                    }
                                                }}
                                            />
                                            {formik.errors.outputFileTypeDropdown && formik.submitCount > 0 && (
                                                <ErrorField errorMessage={formik.errors.outputFileTypeDropdown} />
                                            )}
                                        </div>

                                        { /* Version Field */}
                                        <div className="flex-col justify-start items-start gap-1 flex">
                                            <ModalFieldLabel label={"Version"} />
                                            <InputText
                                                data-testid={'version'}
                                                {...formik.getFieldProps("version")}
                                                className="self-stretch px-3 py-2.5 bg-white rounded border border-zinc-400 justify-start items-center gap-2.5 inline-flex"
                                                pt={{
                                                    root: {
                                                        className: "!text-gray-800"
                                                    }
                                                }}
                                            />
                                            {formik.errors.version && formik.submitCount > 0 && (
                                                <ErrorField errorMessage={formik.errors.version} />
                                            )}
                                        </div>

                                        { /* Executable links Field */}
                                        <RepeatableInputPanel
                                            name={"executables"}
                                            dataTestId={"executablesAddOptionBtn"}
                                            headerLabel={"Executables"}
                                            onAddAnotherOption={() => {
                                                formik.setFieldValue('executableLinks', [...formik.values.executableLinks, { executableLink: "", linkType: "", default: false }]);
                                            }}
                                        >
                                            <FieldArray name={"executableLinks"}>
                                                {({ remove }) => (
                                                    formik.values.executableLinks.map((val: any, index: number) => (
                                                        <div key={`ExecutableLinkFormInput-${index}`}>
                                                            <ExecutableLinkFormInput
                                                                name="executableLinks"
                                                                placeholder="Link to Executable"
                                                                onDelete={remove}
                                                                index={index}
                                                            />
                                                        </div>
                                                    ))
                                                )}
                                            </FieldArray>
                                        </RepeatableInputPanel>

                                        { /* Tool Documentation Field */}
                                        <div className="flex-col justify-start items-start gap-1 flex">
                                            <RepeatableInputPanel
                                                name={ "toolDocumentation" }
                                                headerLabel={ "Tool Documentation" }
                                                onAddAnotherOption={ () => {
                                                    formik.setFieldValue('toolDocumentation', [...( formik.values.toolDocumentation || [] ), '']);
                                                } }
                                            >
                                                <FieldArray name={ "toolDocumentation" }>
                                                    { ({ remove }) => (
                                                        formik.values.toolDocumentation?.map((val: any, index: number) => (
                                                            <ToolDocumentationFormInput
                                                                key={ index }
                                                                onDelete={ () => remove(index) }
                                                                name={ `toolDocumentation` }
                                                                index={ index }
                                                                required={true}
                                                            />
                                                        ))
                                                    ) }
                                                </FieldArray>

                                            </RepeatableInputPanel>
                                        </div>

                                        { /* DOI Field */}
                                        <div className="flex-col justify-start items-start gap-1 flex">
                                            <ModalFieldLabel label={"DOI"} />
                                            <InputText
                                                data-testid={'doi'}
                                                {...formik.getFieldProps("doi")}
                                                className="self-stretch px-3 py-2.5 bg-white rounded border border-zinc-400 justify-start items-center gap-2.5 inline-flex"
                                                pt={{
                                                    root: {
                                                        className: "!text-gray-800"
                                                    }
                                                }}
                                            />
                                            {formik.errors.doi && formik.submitCount > 0 && (
                                                <ErrorField errorMessage={formik.errors.doi} />
                                            )}
                                        </div>

                                        { /* Lab/Institution Field */}
                                        <RepeatableInputPanel
                                            name={"labGroups"}
                                            dataTestId={"labGroupsAddOptionBtn"}
                                            headerLabel={"Lab Group/Institution" }
                                            onAddAnotherOption={ () => {
                                                formik.setFieldValue('institution', [...formik.values.institution, ""])
                                            } }
                                        >
                                            <FieldArray name={ "institution" }>
                                                { ({ remove }) => {
                                                    return (
                                                        formik.values.institution.map((val: any, index: number) => {
                                                            return (
                                                                <RepeatableInputFormGroup
                                                                    key={ `InstitutionFormInput-${ index }` }
                                                                    onDelete={ remove }
                                                                    name="institution"
                                                                    index={ index }
                                                                    dropdownOptions={ props.institutionOptions }
                                                                    text="Lab Group/Institution"
                                                                />
                                                            );
                                                        })
                                                    );
                                                } }
                                            </FieldArray>
                                        </RepeatableInputPanel>
                                    </div>
                                </Form>
                            )}
                        </Formik>

                    </ApiWrapper>
                </Dialog>
            )}
        </div>
    );
}
