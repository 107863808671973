import React, {useEffect, useState} from "react";
import {ExploreMainContent, FiltersSideBar, VerticalNavBar} from "../../components";
import {Outlet, useSearchParams} from "react-router-dom";
import {useGoogleLogin} from "../../hooks/useGoogleLogin.tsx";
import {useDispatch} from "react-redux";
import {login} from "../../store/slices/authSlice.ts";
import {useHasJWT} from "../../hooks/useHasJWT.ts";
import {useGithubLogin} from "../../hooks/useGithubLogin.tsx";
import {ISessionSearchState} from "../../helpers/state/SearchState.ts";
import {LandingPage} from "../../components/LandingPage";
import {consoleWrap} from "../../main.tsx";

export const SideBarContext = React.createContext({});

const ExplorePagePage: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const {googleLogin, data, error, isLoading} = useGoogleLogin();
    const {githubLogin, data: githubSuccessData, error: githubError, isLoading: githubIsLoading} = useGithubLogin();
    const [googleCode, setGoogleCode] = useState('');
    const [githubCode, setGithubCode] = useState('');


    const {hasJWT, accessToken} = useHasJWT();

    const dispatch = useDispatch();

    useEffect(() => {
        if (hasJWT()) {
            dispatch(login());
        }
    }, []);

    useEffect(() => {
        const code = searchParams.get('code');
        const provider = searchParams.get('provider');
        if (provider && provider === 'github') {
            if (code && code !== githubCode) {
                setGithubCode(code);
                setSearchParams('')
            }
        } else {
            if (code && code !== googleCode) {
                setGoogleCode(code);
                setSearchParams('')
            }
        }

    }, [searchParams]);

    useEffect(() => {
        if (googleCode) {
            googleLogin(googleCode);
        }
    }, [googleCode]);

    useEffect(() => {
        if (githubCode) {
            githubLogin(githubCode);
        }
    }, [githubCode]);

    return (
        <>
            <div className="w-screen h-screen flex flex-row">
                <VerticalNavBar />
                <Outlet />
            </div>
        </>
    );
};

export default ExplorePagePage;

export const ExploreDefaultPage: React.FC = () => {
    const [isSideBarOpen, setIsSideBarOpen] = useState(false);
    const [searchResult, setSearchResult] = useState<any>(null);
    const [shouldDisplayLandingPage, setShouldDisplayLandingPage] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');

    const onSearchResult = (result: any) => {
        setSearchResult(result);
    }


    const onLandingPageSearchClick = (value: string) => {
        setShouldDisplayLandingPage(false);
        setSearchTerm(value);
    }

    useEffect(() => {
        const savedSearchString = sessionStorage.getItem("searchState");
        if (savedSearchString) {
            const savedSearch: ISessionSearchState = JSON.parse(savedSearchString);
            if (savedSearch.results && savedSearch.results.tools.length > 0) {
                setShouldDisplayLandingPage(false);
            }

            if (savedSearch.results && savedSearch.results.pipelines.length > 0) {
                setShouldDisplayLandingPage(false);
            }
        }
    }, []);

    return (
        <SideBarContext.Provider value={{isSideBarOpen, setIsSideBarOpen}}>
            <FiltersSideBar setFilterSearchResult={onSearchResult}/>
            {shouldDisplayLandingPage ? <LandingPage onSearchClick={onLandingPageSearchClick} /> : <ExploreMainContent searchResult={searchResult} searchTerm={searchTerm}/>}
        </SideBarContext.Provider>
    )
};




