import {ReactSVG} from "react-svg";
import {getFirstTwoLetters} from "./utils.ts";
import {IExecutableLink} from "../../hooks/useSearch.tsx";
import {useNavigate} from "react-router-dom";
import {CodeLinkSplitButton} from "../CodeLinkButton/CodeLinkSplitButton.tsx";
import { LikeButton } from "../LikeButton/LikeButton.tsx";
import { consoleWrap } from "../../main.tsx";
import {ToolDocLinkButton} from "../CodeLinkButton/ToolDocLinkButton.tsx";

const Initials = ({initials}: {initials: string}) => {
    return (
        <>
            <div className="w-8 h-7 bg-deep_purple rounded-full  flex flex-col items-center justify-center">
                <span className="text-xs text-white font-semibold">{initials}</span>
            </div>
        </>
    )
}

const ResultPipelineNames = ({name, datatype, onResultClick}: {name: string, datatype: string | string[], onResultClick: () => void}) => {
    const displayDatatype = Array.isArray(datatype) ? datatype.join(', ') : datatype;
    return (
        <>
            <div className="h-[48px] w-[180px] flex flex-col items-start">
                <div className="text-pp_name font-semibold text-interactive cursor-pointer" onClick={onResultClick}>{name}</div>
                <div className="text-sm font-normal mt-1 text-gray-700">{displayDatatype}</div>
            </div>
        </>
    )
}

const ResultPipelineAuthor = ({initials, labName}: {initials: string, labName: string}) => {
    return (
        <>
            <div className="flex flex-col justify-center w-[180px]">
                <div className="flex flex-row items-baseline">
                    <Initials initials={initials} />
                    <div className="pl-2 w-full flex flex-auto flex-col items-start justify-center text-sm font-normal overflow-visible">
                        <span className="whitespace-normal break-words">
                            {labName}
                        </span>
                    </div>
                </div>
            </div>
        </>
    )
}

const ResultPipelineRating = ({likes, users, isUserLiked}: {likes: number, users: number, isUserLiked: boolean}) => {
    return (
        <>
            <div className="h-[48px] w-[180px] flex flex-row justify-start">
                <div className="flex flex-row items-center">
                    <LikeButton
                        likeCount={ likes }
                        isLiked={ isUserLiked }
                        isClickable={ false }
                    />
                </div>
            </div>
        </>
    )
}

export interface ISearchResultProps {
    searchResultType: 'pipeline' | 'tool';
    name: string;
    datatype: string;
    labName: string;
    likes: number;
    isUserLiked: boolean;
    users: string;
    url: string[];
    id: string;
    purposes?: any[]
    executableLinks: any;
}

export const SearchResult = ({
                                 id,
                                 searchResultType,
                                 name,
                                 datatype,
                                 labName,
                                 likes,
                                 isUserLiked,
                                 users,
                                 url, // url is already an array here
                                 purposes,
                                 executableLinks
                             }: ISearchResultProps) => {
    const navigate = useNavigate();
    const initials = getFirstTwoLetters(labName);

    const goToPipelineDetail = () => {
        navigate(`pipeline/${id}`, {state: {purposes}});
    };

    const goToToolDetail = () => {
        navigate(`tool/${id}`);
    };

    return (
        <div className="h-[88px] w-full">
            <div className="flex self-auto content-between flex-wrap justify-between items-stretch py-5">
                <ResultPipelineNames
                    name={name}
                    datatype={datatype}
                    onResultClick={() => {
                        searchResultType === 'pipeline' ? goToPipelineDetail() : goToToolDetail();
                    }}
                />
                <ResultPipelineAuthor initials={initials} labName={labName} />
                <ResultPipelineRating likes={+likes} users={+users} isUserLiked={isUserLiked} />
                {searchResultType === 'pipeline'
                    ? <CodeLinkSplitButton executableLinks={executableLinks}/>
                    : <ToolDocLinkButton urlArray={url} />}
            </div>
        </div>
    );
};
