import {useAxios} from "./useAxios.tsx";
import {IPipeline, ITool} from "./useSearch.tsx";
import { IGraph } from "../models/Graph/IGraph.ts";

export interface IGetPipelineByIdData {
    graph: IGraph
    pipeline: IPipeline,
    tools: ITool[],
}

class UseAxios {
    data: any;
    isLoading: boolean = false;
    error: any;

    commonFetch(options: any): any {

    }
}

class GetPipelineById extends UseAxios {
    commonFetch(options: any): Promise<any> {
        return super.commonFetch(options);
    }
}

export interface IGetPipelineByIdResponse {
    data: IGetPipelineByIdData,
    error: any;
    isLoading: boolean;
    commonFetch: (options: any) => Promise<any>;
}

export const useGetPipelineById = (pipelineId: string) => {
    const { commonFetch, isLoading, data, error }: IGetPipelineByIdResponse = useAxios<any>({
        url: `/data/pipeline/${pipelineId}/unfiltered`,
        method: "GET",
    });

    const getPipelineById = async () =>  await commonFetch({});

    return { getPipelineById, isLoading, data, error };
};
