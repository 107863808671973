import {useAxios} from "./useAxios.tsx";
import { INode } from "../models/INode.ts";
import { IGraphLink } from "../models/Graph/IGraphLink.ts";
import { DocumentLikeDataType } from "../models/DocumentLikeDataType.ts";

export interface IExecutableLink {
    _id: string;
    executableLink: string;
    linkType: string;
    default: boolean;
}

export interface IPurposePipeline {
    text: string;
    nodes: INode[];
}

export interface IPurposeTool {
    _id: string;
    text: string;
    executableLinks: IExecutableLink[];
}

export interface IUser {
    _id: string;
    name: string;
    link: string;
}

export interface IPipeline {
    _id: string;
    core: boolean;
    datatype: string[];
    downloads: number;
    graphLinks: IGraphLink[];
    link: string;
    main: {
        nodes: any[];
        executableLinks: IExecutableLink[];
    };
    name: string;
    num_clicks: number;
    purposes: IPurposePipeline[];
    score: number;
    subBranches: any[];
    submittedBy: string;
    user: IUser[];
    verified: boolean;
    version: string;
    weight_score: number;
}

export interface ITool {
    _id: string;
    doi: string;
    input: string[];
    keywords: string[];
    link: string;
    name: string;
    num_clicks: number;
    outputs: string[];
    purposes: IPurposeTool[];
    score: number;
    type: string;
    user: string[]
    versions: string[];
    weight_score: number;
}

export type RankedExecutablePipeline = IPipeline & { num_clicks: number; weight_score: number; };
export type RankedExecutableTool = ITool & { num_clicks: number; weight_score: number; };
export type RankedExecutableType = RankedExecutablePipeline | RankedExecutableTool;

// export type ExecutableType = ITool | IPipeline;
// export type RankedExecutableType = ExecutableType & { num_clicks: number; weight_score: number  };
export interface ISearchResultItem {
    rankedItem: RankedExecutableType;
    likeData: DocumentLikeDataType;
}

export interface ISearchResult {
    tools: ISearchResultItem[];
    pipelines: ISearchResultItem[];
}

export interface IGetSearchAxiosResponse {
    data: ISearchResult;
    error: any;
    isLoading: boolean;
    commonFetch: (options: any) => Promise<any>;
}

export const useSearch = () => {
    const { commonFetch, isLoading, data, error }: IGetSearchAxiosResponse = useAxios<any>({
        url: "/data/search",
        method: "GET",
    });

    const getSearch = async (queryParams: {question: string, pipelineToolOnly: boolean}) =>
        await commonFetch({queryParameters: queryParams});

    return { getSearch, isLoading, data, error };
};
