import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { ApiWrapper } from "../../components/ApiWrapper/ApiWrapper.tsx";
import { CodeLinkSplitButton } from "../../components/CodeLinkButton/CodeLinkSplitButton.tsx";
import { ToolDocLinkButton} from "../../components/CodeLinkButton/ToolDocLinkButton.tsx";
import { Button } from "primereact/button";
import { Divider } from 'primereact/divider';
import { useGetToolById } from "../../hooks/useGetToolById.tsx";
import ReactFlow, { Background, Controls } from 'reactflow';
import 'reactflow/dist/style.css';
import { ToolNode } from "../../components/ReactFlow/CustomNodes/ToolNode.tsx";
import { useGetExecutableLikesForTool } from "../../hooks/useGetExecutableLikesForTool.tsx";
import { consoleWrap } from "../../main.tsx";
import { LikeButton } from "../../components/LikeButton/LikeButton.tsx";
import { DocumentLikeDataType } from "../../models/DocumentLikeDataType.ts";
import {PDFDownloadLink} from "@react-pdf/renderer";
import {ToolPDF} from "../../pdf/ToolPDF.tsx";
import {ITool} from "../../hooks/useSearch.tsx";

export const ToolDetailPage: React.FC = () => {

    const { id } = useParams<{ id: string }>();

    const { getGetToolById, isLoading, data, error } = useGetToolById();

    if (id) {

        const {
            getExecutableLikesForTool,
            isLoading: likesIsLoading,
            data: likesDataResponse,
            error: likesError
        } = useGetExecutableLikesForTool(id);

        useEffect(() => {
            if (id) {
                getGetToolById({ id, getAssociatedTools: true });
                getExecutableLikesForTool();
            }
        }, []);

        const nodes = useMemo(() => [
            {
                id: '1',
                data: {
                    label: data?.name,
                    externalLinkLabel: data?.purposes[0]?.executableLinks[0]?.linkType ? data?.purposes[0]?.executableLinks[0]?.linkType : '',
                    inProgress: false,
                    type: 'any'
                },
                position: { x: 0, y: 0 },
                type: 'toolNode',
            },
        ], [data]);

        const nodeTypes = useMemo(() => ({
            toolNode: ToolNode
        }), []);

        const [likesData, setLikesData] = useState<DocumentLikeDataType>({likes: 0, userLiked: false});

        // Update likes data state when likes data response changes
        useEffect(() => {
            if (likesDataResponse) {
                setLikesData(likesDataResponse);
            }
        }, [likesDataResponse]);

        const ToolInfoViewLine = (props: { label: string, value: any }) => {
            const {label, value} = props;
            return (
                <div className=" justify-start items-start gap-2 inline-flex text-sm h-fit ">
                    <div className=" w-20 text-neutral-500 min-w-20 ">
                        <p className="font-inter text-gray-700">{label}:</p>
                    </div>
                    <div className="grow shrink basis-0 text-neutral-800  ">
                        {value}
                    </div>
                </div>
            );
        }

        const HyperLink = (props: { link: string, text: string }) => {
            const { link, text } = props;
            return (
                <div>
                    <a href={ link }
                       rel='nonreferrer noopener'
                       target={ self ? '_self' : '_blank' }
                       className="text-blue-700 text-sm font-normal leading-tight break-all">
                        { text }
                    </a>

                </div>
            );
        }

        const passThroughProps = {
            root: {
                className: 'text-white bg-blue-500 border border-blue-500 hover:bg-blue-600 hover:border-blue-600\': props.severity === \'info\' && !props.text && !props.outlined && !props.plain'
            },

        };

        consoleWrap.log(data)

        return (
            <div className="h-full overflow-scroll w-full">
                <ApiWrapper isLoading={ isLoading } error={ error } data={ data } loadingLabel={ "Loading Tool..." } errorLabel="ERROR!"> {

                    <div className="flex flex-col items-center gap-5 py-7">
                        {/* Top bar */}
                        <div className="title w-2/3">
                            <div className="flex w-full justify-center">
                                <div className="flex flex-row w-full">
                                    <div className="flex-1">
                                        <div className="font-inter text-2xl">{data?.name}</div>
                                        {/*<p className="text-neutral-500 text-sm font-normal font-inter leading-tight">*/}
                                        {/*    {likesData ? likesData.likes : "..."} { likesData && likesData.likes !== 1 ? "Likes" : "Like" }*/}
                                        {/*</p>*/}
                                    </div>
                                    <div className="flex flex-row items-center gap-2">
                                        {
                                            likesData &&
                                            <LikeButton
                                                likeCount={ likesData.likes }
                                                isLiked={ likesData.userLiked }
                                                isClickable={ true }
                                                likeActionDetails={{
                                                    datatype: "tool",
                                                    datatypeId: id,
                                                    onLikesChanged: (newLikes) => {
                                                        // Update the likes data when the user likes or unlikes the tool
                                                        setLikesData(newLikes);
                                                    }
                                                }}
                                            />
                                        }

                                        {
                                            data && data.link && (
                                                <ToolDocLinkButton urlArray={data.link} />  // Pass the entire link array to ToolDocLinkButton
                                            )
                                        }
                                        {data && (
                                            <PDFDownloadLink
                                                document={<ToolPDF toolData={data} id={id}/>}  // Ensure data is passed as ITool
                                                fileName={`Tool-${data?.name}.pdf`}  // Customize file name
                                            >
                                                {({ loading }) =>
                                                    loading ? (
                                                        <Button pt={passThroughProps}  className="h-[32px] !py-0 bg-pipeline_diagram_view_button text-white rounded shadow border border-pipeline_diagram_view_button" label="Generating PDF..."></Button>
                                                    ) : (
                                                        <Button pt={passThroughProps}  className="h-[32px] !py-0 bg-pipeline_diagram_view_button text-white rounded shadow border border-pipeline_diagram_view_button"label="Export"></Button>
                                                    )
                                                }
                                            </PDFDownloadLink>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Tool Preview */}
                        <div className="w-4/5 h-96 ">
                            <div
                                className="w-full h-full border flex items-center justify-center rounded-lg items-stretch">
                                <ReactFlow
                                    fitView={ true }
                                    nodes={nodes}
                                    proOptions={{hideAttribution: true}}
                                    nodeTypes={ nodeTypes }
                                >

                                    <Background gap={ 12 } size={ 1 }/>
                                    <Controls showInteractive={false}/>
                                </ReactFlow>
                            </div>

                        </div>
                        {/* Tool detail list */}
                        <div className="w-2/3">
                            <div className="text-neutral-800 font-normal flex flex-col">
                                <p className="text-base font-semibold font-inter text-neutral-800 leading-tight">
                                    { data?.name }
                                    { data?.versions.toString() != "" ? ` (${ data?.versions })` : "" }
                                </p>
                                <div className="text-neutral-800 font-normal flex flex-col">
                                    { ( data?.keywords && data?.keywords.length > 0 ) &&
                                        <ToolInfoViewLine label={ "Keywords" } value={ data.keywords.join(', ') }></ToolInfoViewLine>
                                    }
                                    <ToolInfoViewLine
                                        label={ "Purpose" }
                                        value={
                                            <>
                                                <Divider />
                                                {/* Multiple purposes display each executable */}
                                                {data?.purposes.map((purpose: { text: string, executableLinks: any }) => (
                                                    <>
                                                        <div className="flex justify-between items-center">
                                                            <div className="w-[569px]">
                                                                {purpose.text}
                                                            </div>
                                                            {purpose.executableLinks[0]?.executableLink && (
                                                                <CodeLinkSplitButton executableLinks={purpose.executableLinks} />
                                                            )}
                                                        </div>
                                                        <Divider />
                                                    </>
                                                ))}
                                            </>
                                        }
                                    />
                                    <ToolInfoViewLine label={ "Input" }
                                                      value={  data?.input.map((input) => input).join(', ') }/>
                                    <ToolInfoViewLine label={ "Output" }
                                                      value={   data?.output.map((output) => output).join(', ') }/>
                                    {/*<ToolInfoViewLine label={ "Score" } value={ data?.score ? data?.score : 'Unavailable'}/>*/}
                                    <ToolInfoViewLine label={ "DOI" } value={ data?.doi ?
                                        <HyperLink link={ data?.doi} text={ data?.doi }/> : 'Unavailable' }/>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                </ApiWrapper>

            </div>
        );
    }
    else {
        return <div>No tool ID provided</div>;
    }
};

