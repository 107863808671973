import {useContext, useState} from "react";
import {SideBarContext} from "../../pages/Home";
import {ToggleButton, ToggleButtonChangeEvent} from "primereact/togglebutton";
import {Checkbox} from "primereact/checkbox";

export const ToolBarButtons = () => {
    const {isSideBarOpen, setIsSideBarOpen}: any = useContext(SideBarContext)
    const [checked, setChecked] = useState<boolean>(false);
    return (
        <div className="flex py-2 w-full px-[60px]">
            <ToggleButton
                onLabel="Filters"
                offLabel="Filters"
                onIcon={(options: any) => <img alt="close icon" src="images/close-icon-blue.svg" {...options.iconProps} className="w-5 h-5" />}
                offIcon="pi pi-sliders-h"
                checked={isSideBarOpen}
                onChange={(e:  ToggleButtonChangeEvent) => setIsSideBarOpen(e.value)} />
        </div>
    )
}
