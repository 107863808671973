import { useAxios } from "../useAxios.tsx";

export interface IFormInitializationValue {
    label: string,
    value: string
}

export interface IInitializeFormResponse {
    datatypes: IFormInitializationValue[];
    toolUsers: IFormInitializationValue[];
}

export interface IGetProcessedDataTypesAxiosResponse {
    data: IInitializeFormResponse;
    error: any;
    isLoading: boolean;
    commonFetch: (options: any) => Promise<any>;
}

export const useInitializeForm = () => {
    const { commonFetch, isLoading, data, error }: IGetProcessedDataTypesAxiosResponse = useAxios<any>({
        url: "/view/pipeline-builder/initialize-form",
        method: "GET",
    });

    const getInitializedFormData = async () =>
        await commonFetch({});

    return { getInitializedFormData, isLoading, data, error };
}
