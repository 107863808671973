import { TabPanel, TabPanelHeaderTemplateOptions, TabView } from "primereact/tabview";
import { ReactSVG } from "react-svg";
import { css } from "glamor";
import { SearchResult } from "./SearchResult.tsx";
import {
    IExecutableLink,
    ISearchResultItem,
    RankedExecutablePipeline,
    RankedExecutableTool
} from "../../hooks/useSearch.tsx";
import {ToolUser, useGetToolUsers} from "../../hooks/useGetToolUsers.ts";
import {useEffect, useState} from "react";

const BLUE = '#2463BC';
const BLACK = '#000000';

const getStyles = (selected: boolean) => {
    return css({
        ' svg': {
            width: '20px',
            height: '20px'
        },
        ' path': {
            fill: `${selected ? BLUE : BLACK}`,
        }
    });
}

export interface ISearchResultsTabViewProps {
    numPipelines: number;
    numTools: number;
    pipelines: ISearchResultItem[];
    tools: ISearchResultItem[];
}

export const SearchResultsTabView = ({numPipelines, numTools, pipelines, tools}: ISearchResultsTabViewProps) => {

    const { getToolUsers, isLoading, data: toolUsers } = useGetToolUsers();
    const [labNames, setLabNames] = useState<{ [key: string]: string }>({}); // Store lab names by tool ID

    useEffect(() => {
        getToolUsers();
    }, []);


    const pipelineHeaderTemplate = (options: TabPanelHeaderTemplateOptions) => {

        const pipeLineIconStyles = getStyles(options.selected);
        return (
            <div className={`flex flex-col py-2 pl-2 pr-4 h-9 ${options.selected ? 'bg-selected text-interactive' : 'bg-subdued'}`}>
                <div className={`flex flex-row gap-x-1`} onClick={options.onClick}>
                    <ReactSVG src="images/pipeline-icon.svg"  className="w-5 h-5" {...pipeLineIconStyles} />
                    <span className="px-2">Pipelines</span>
                    <span className={`ml-auto`}>{numPipelines}</span>
                </div>
            </div>
        )
    };

    const toolsHeaderTemplate = (options: TabPanelHeaderTemplateOptions) => {
        const toolsIconStyles = getStyles(options.selected);
        return (
            <div className={`flex flex-col py-2 pl-2 pr-4 h-9 ${options.selected ? 'bg-selected text-interactive' : 'bg-subdued'}`}>
                <div className={`flex flex-row gap-x-1`} onClick={options.onClick}>
                    <ReactSVG src="images/tools-icon.svg"  className="w-5 h-5" {...toolsIconStyles} />
                    <span className="px-2">Tools</span>
                    <span className={`ml-auto ${options.selected ? 'text-interactive' : ''}`}>{numTools}</span>
                </div>
            </div>
        )
    };

    const getLabNames = (arr: {name: string}[]) => {
        return arr.map((item) => item.name).join(', ');
    }

    const getLabNameByUserId = (userId: string) => {
        const user = toolUsers?.find((u: ToolUser) => u._id === userId);
        return user?.name || "Unknown"; // Return the lab name if found, otherwise "Unknown"
    };

    useEffect(() => {
        tools.forEach((searchResultItem) => {
            const tool = searchResultItem.rankedItem as RankedExecutableTool;

            if (tool && tool.user && tool.user.length > 0) {
                const labNamesArray = tool.user.map((user) => getLabNameByUserId(user)); // Fetch lab names for each user

                const joinedLabNames = labNamesArray.join(', '); // Join lab names with a comma
                setLabNames((prev) => ({ ...prev, [tool._id]: joinedLabNames }));

            }
        });
    }, [tools, toolUsers]);  // Trigger whenever tools or toolUsers changes

    return (
        <>
            <TabView pt={{
                root: { className: 'w-full px-[60px] mt-[40px] overflow-y-hidden' },
            }}>
                <TabPanel header="Pipelines" headerTemplate={pipelineHeaderTemplate} pt={{
                    content: { className: 'h-full overflow-y-auto ' },
                }}>
                    {
                        pipelines.map((searchResultItem: ISearchResultItem) => {
                            let pipeline: any;
                            let likeCount: number = 0;
                            let userLiked = false;
                            if (searchResultItem.hasOwnProperty('rankedItem')) {
                                pipeline = searchResultItem.rankedItem as RankedExecutablePipeline;
                                likeCount = searchResultItem.likeData.likes;
                                userLiked = searchResultItem.likeData.userLiked;
                            } else {
                                pipeline = searchResultItem as any;
                            }
                            const executableLinks: IExecutableLink[] = pipeline?.main.executableLinks;
                            const defaultUrl = executableLinks?.find((link: IExecutableLink) => link.default);

                            //consoleWrap.log(pipeline?.main?.executableLinks)

                            return (
                                <SearchResult
                                    searchResultType={'pipeline'}
                                    purposes={pipeline?.purposes}
                                    id={pipeline?._id}
                                    key={pipeline?._id}
                                    name={pipeline?.name}
                                    datatype={pipeline?.assayTypes || ''}
                                    labName={getLabNames(pipeline?.user)}
                                    likes={ likeCount }
                                    isUserLiked={ userLiked }
                                    users="0"
                                    url={defaultUrl ? [defaultUrl.executableLink] : []}
                                    executableLinks = {executableLinks}
                                />
                            )
                        })
                    }
                </TabPanel>
                <TabPanel header="Tools" headerTemplate={toolsHeaderTemplate} pt={{
                    content: { className: 'h-full overflow-y-auto' },
                }}>
                    {
                        tools.map((searchResultItem, index) => {
                            let tool: any;
                            let likeCount: number = 0;
                            let userLiked = false;

                            if(searchResultItem.hasOwnProperty('rankedItem')) {
                                tool = searchResultItem.rankedItem as RankedExecutableTool;
                                likeCount = searchResultItem.likeData.likes;
                                userLiked = searchResultItem.likeData.userLiked;
                            } else {
                                tool = searchResultItem as any;
                            }

                            // NEED TO FIX THIS (each purpose has multiple executable links)
                            //consoleWrap.log(tool?.purposes[0].executableLinks)


                            return (
                                <SearchResult
                                    searchResultType={'tool'}
                                    id={tool._id}
                                    key={tool._id}
                                    name={tool.name}
                                    datatype={tool.type}
                                    labName={labNames[tool._id] || "Unknown"}  // Display the fetched lab name
                                    likes={ likeCount }
                                    isUserLiked={ userLiked }
                                    users="0"
                                    url={tool.link}
                                    executableLinks = {tool}
                                />
                            )
                        })
                    }
                </TabPanel>
            </TabView>
        </>
    )
};
