import React, { useContext } from "react";
import { PipelineInformationRightPanel } from "./RightPanelChildren/PipelineInformationRightPanel.tsx";
import { AddToolRightPanel } from "./RightPanelChildren/AddToolRightPanel.tsx";
import { AddPipelineRightPanel } from "./RightPanelChildren/AddPipelineRightPanel.tsx";
import { IInitializeFormResponse } from "../../hooks/pipeline-builder/useInitializeForm.ts";
import {
    BuilderPipelineSelectedNodeContext
} from "../../store/context/PipelineBuilder/builder-pipeline-selected-node-context.ts";
import { BuilderToolNodeIds } from "./Flow/Nodes/BuilderToolNode.tsx";

interface BuilderRightPanelProps {
    initDropdownData: IInitializeFormResponse;
}

export const BuilderRightPanel = (props: BuilderRightPanelProps) => {

    // const rightPanelContext = useContext(BuilderRPanelContext);
    const selectedNodeContext = useContext(BuilderPipelineSelectedNodeContext);

    let content = <>
        <PipelineInformationRightPanel
            processDatatypeOptions={ props.initDropdownData ? props.initDropdownData.datatypes : [] }
            institutionOptions={ props.initDropdownData ? props.initDropdownData.toolUsers : [] }
        />
    </>
    
    // Check if selectedNode is defined. If it isn't, leave content in default state.
    if (selectedNodeContext.selectedNode) {
        const { type } = selectedNodeContext.selectedNode;
        if (type === BuilderToolNodeIds.NODE_TYPE) {
            content = (
                <AddToolRightPanel
                    institutionOptions={props.initDropdownData ? props.initDropdownData.toolUsers : []}
                />
            );
        } else if (type === "pipeline") {
            // Render AddPipelineRightPanel if the node type is "pipeline"
            content = <AddPipelineRightPanel data={ undefined }/>;
        }
    }

    return (
        <div
            className={ `w-80 max-w-xs h-full flex-col justify-start items-start gap-4 inline-flex border-l border-gray-200` }>
            { content }
        </div>
    );
}


