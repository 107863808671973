import { AvatarGroup } from "primereact/avatargroup";
import { Avatar } from "primereact/avatar";
import React from "react";
import { IUser } from "../../hooks/useSearch.tsx";

// Listing these class names is a roundabout way to get the avatars to show up in the right colors.
// Tailwind doesnt support dynamic class names directly in the markup due to its nature of purging unused styles for production builds.
// Thus, we need to specify the entirety of the classNames in advance and then use them in the markup. Otherwise I'd just use a list of colors and generate the class names dynamically.
const avatarClassNames = [
    "w-7 h-7 border-2 border-white text-white text-center text-xs font-semibold font-inter leading-none -ml-2.5 border-2 border-white dark:border-gray-900 bg-accent_purple",
    "w-7 h-7 border-2 border-white text-white text-center text-xs font-semibold font-inter leading-none -ml-2.5 border-2 border-white dark:border-gray-900 bg-accent_green",
    "w-7 h-7 border-2 border-white text-white text-center text-xs font-semibold font-inter leading-none -ml-2.5 border-2 border-white dark:border-gray-900 bg-accent_teal",
    "w-7 h-7 border-2 border-white text-white text-center text-xs font-semibold font-inter leading-none -ml-2.5 border-2 border-white dark:border-gray-900 bg-accent_blue",
    "w-7 h-7 border-2 border-white text-white text-center text-xs font-semibold font-inter leading-none -ml-2.5 border-2 border-white dark:border-gray-900 bg-accent_red",
    "w-7 h-7 border-2 border-white text-white text-center text-xs font-semibold font-inter leading-none -ml-2.5 border-2 border-white dark:border-gray-900 bg-accent_yellow",
    "w-7 h-7 border-2 border-white text-white text-center text-xs font-semibold font-inter leading-none -ml-2.5 border-2 border-white dark:border-gray-900 bg-accent_olive",
]

interface UserAvatarGroupProps {
    users: IUser[];
}

export const UserAvatarGroup = (props: UserAvatarGroupProps) => {

    return (
        <>
            <AvatarGroup>
                {
                    props.users.map((user, index) => {
                        const avatarClassName = avatarClassNames[index % avatarClassNames.length]
                        const avatarInitials = user.name.split(" ").slice(0, 2).map((word: string) => word[0]).join("");
                        return <Avatar
                            label={ avatarInitials }
                            size={ "large"}
                            shape="circle"
                            className={ avatarClassName }
                            key={ user._id }
                        />
                    })
                }
            </AvatarGroup>
        </>
    );
};
