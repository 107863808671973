import { StyleSheet } from '@react-pdf/renderer';

export const pdfStyles = StyleSheet.create({
    page: {
        padding: 32,  // Roughly 0.75 inches
        flexDirection: 'column',
        backgroundColor: '#fff',
    },
    header: {
        marginBottom: 15,
        paddingBottom: 10,
        borderBottom: '1px solid #ccc',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    logo: {
        width: 85,  // Adjust size as needed
        height: 25,
        position: 'absolute',
        top: 0,
        right: 0,
    },
    titleSection: {
        flexDirection: 'column',
        marginBottom: 4,  // Spacing between the title and lab name
    },
    headerTitleLink: {
        textDecoration: 'none',
    },
    headerTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#1a0dab',
        textDecoration: 'none', // Remove underline
    },
    labInfoText: {
        fontSize: 10,
        color: '#666',
        marginTop: 5,  // Space between the title and lab name
    },
    linkContainer: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop: 3,  // Reduced space between lab name and executable links
    },
    linkButton: {
        fontSize: 10,
        color: '#1a0dab',
        textDecoration: 'underline',
        marginBottom: 2,
    },
    noLinkButton: {
        fontSize: 10,
        marginBottom: 2,
        color: '#444',
    },
    section: {
        borderBottom: '1px solid #ccc',
        paddingTop: 8,
    },
    title: {
        fontSize: 14, // Section title font size
        fontWeight: 'bold',
        color: '#333',
        marginBottom: 5,
    },
    content: {
        fontSize: 9,
        color: '#444',
        marginBottom: 6,
    },
    executableSection: {
        marginBottom: 6,
    },
    executableLabel: {
        fontSize: 9, // Match font size with content
        color: '#444',
        marginBottom: 4,
    },
    linkText: {
        fontSize: 9,
        color: '#1a0dab',
        textDecoration: 'none',
    },
    subtitle: {
        fontSize: 12,
        fontWeight: 'bold',
        marginBottom: 4,
        color: '#333',
    },
    userInfoText: {
        fontSize: 10,
        color: '#666',
    },
    unavailableText: {
        fontSize: 9,
        color: '#000',
    },
    listItem: {
        fontSize: 9,
        color: '#1a0dab',
        textDecoration: 'none',
        marginLeft: 8,
        marginTop: 3,
    }
});