import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import 'primeicons/primeicons.css';
import {Button} from 'primereact/button';
import {TabPanel, TabPanelHeaderTemplateOptions, TabView} from "primereact/tabview";
import {ConfirmDialog} from "primereact/confirmdialog";
import {IPipelineTableRowData, PipelineTable} from "../../components/PipelineTable/PipelineTable.tsx";
import {ToolTable} from "../../components/ToolTable/ToolTable.tsx";
import {useInitializeForm} from "../../hooks/pipeline-builder/useInitializeForm.ts";
import {PipelineInfoModal} from "../../components/Builder/PipelineInfo/PipelineInfoModal.tsx";
import {useGetUserProfile} from "../../hooks/useGetUserProfile.ts";
import {
    IPipelineBuilderTableRowResponse,
    useGetPipelineDraftTableData
} from "../../hooks/pipeline-builder/useGetPipelineDraftTableData.ts";
import {IToolTableDataResponse, useGetToolTableData} from "../../hooks/useGetToolTableData.tsx";
import {ApiWrapper} from "../../components/ApiWrapper/ApiWrapper.tsx";
import {resetBuilderSaveDraft} from "../../store/slices/Builder/BuilderSaveDraftState.ts";
import {resetPipelineInfo} from "../../store/slices/Builder/BuilderPipelineInfoState.ts";
import {doRefreshToken} from "../../hooks/useRefreshToken.tsx";
import ToolInfoModal from "../../components/Builder/ToolInfo/ToolInfoModal.tsx";
import {Toast} from 'primereact/toast';
import {useDeleteTool} from "../../hooks/useDeleteTool.tsx";
import {deleteTool as deleteToolAction, setToolsData} from "../../store/slices/toolsSlice.ts";
import {RootState} from "../../store/store.ts";
import {EditToolModal} from "../../components/Builder/ToolInfo/EditToolModal.tsx";
import {EditToolResponse} from "../../hooks/useEditTool.tsx";

/**
 * Formats a date string into a specific format without time.
 *
 * @param {string} dateString - The date string to format.
 * @returns {string} The formatted date string.
 */
function formatDate(dateString: string): string {
    const date = new Date(dateString);
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    return `${ monthNames[monthIndex] } ${ day }, ${ year }`;
}

/**
 * Formats a date string into a specific format.
 *
 * @param {string} dateString - The date string to format.
 * @returns {string} The formatted date string.
 */
const formatDateTime = (dateString: string): string => {
    if (!dateString) return "N/A"; // Handling null or undefined input
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
        return "Invalid date"; // Handling invalid date strings
    }
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    };
    return new Intl.DateTimeFormat('en-US', options).format(date);
};

export const BuildPage: React.FC = () => {

    // State that controls the visibility of the PipelineInfoModal
    const [pipelineInfoModalVisible, setPipelineInfoModalVisible] = useState(false);
    const [toolInfoModalVisible, setToolInfoModalVisible] = useState(false);
    const [tableData, setTableData] = useState<IPipelineTableRowData[]>([]);
    const [activeTab, setActiveTab] = useState(0);
    const [selectedTool, setSelectedTool] = useState<IToolTableDataResponse | null>(null);
    const [editToolModalVisible, setEditToolModalVisible] = useState(false);

    const handleToolNameClick = (toolData: IToolTableDataResponse) => {
        setSelectedTool(toolData);
        setEditToolModalVisible(true);
    };
    const [toolDeleteErrorModalVisible, setToolDeleteErrorModalVisible] = useState(false);

    const toolsData : IToolTableDataResponse[] = useSelector((state: RootState) => state.tools.rows);

    const toast = useRef<Toast>(null);

    const dispatch = useDispatch();

    // Custom hook to get the user profile
    const {
        getUserProfile,
        data: userProfileData,
        isLoading: userProfileLoading,
        error: userProfileError,
    } = useGetUserProfile();

    // Custom hook to get the initialized form data for the PipelineInfo
    const {
        getInitializedFormData,
        data: initializedFormData,
        isLoading,
        error,
    } = useInitializeForm();

    const {
        getPipelineDraftTableData,
        data: pipelineDraftData,
        isLoading: pipelineDraftDataLoading,
        error: pipelineDraftDataError,
    } = useGetPipelineDraftTableData();

    const {
        getToolTableData,
        data: toolTableData,
        isLoading: toolTableDataLoading,
        error: toolTableDataError,
    } = useGetToolTableData();

    const {
        deleteTool,
        data: deleteToolData,
        isLoading: deleteToolIsLoading,
        error: deleteToolError,
    } = useDeleteTool();

    const isFirstRender = useRef(true);

    useEffect(() => {
        // account for react strict mode
        if (isFirstRender.current) {
            doRefreshToken().then(() => {
                dispatch(resetBuilderSaveDraft());
                dispatch(resetPipelineInfo());
                getInitializedFormData();
                getUserProfile();
                getPipelineDraftTableData();
                getToolTableData();
            });
            isFirstRender.current = false;
        }
    }, []);

    useEffect(() => {
        dispatch(setToolsData(toolTableData));
    }, [toolTableData]);

    useEffect(() => {
        if (deleteToolData) {
            dispatch(deleteToolAction(deleteToolData.toolId));
        }
    }, [deleteToolData]);


    useEffect(() => {
        if (deleteToolError) {
            setToolDeleteErrorModalVisible(true);
        }
    }, [deleteToolError]);

    // Whenever the pipelineDraftData or the approved pipelines change, update the table data
    useEffect(() => {
        if (pipelineDraftData) {
            const combinedData: IPipelineBuilderTableRowResponse[] = [...pipelineDraftData.drafts, ...pipelineDraftData.pipelines];
            const newTableData: IPipelineTableRowData[] = combinedData.map((pipelineRow) => {
                const editedOnDate = pipelineRow.editedOn ? formatDateTime(pipelineRow.editedOn) : '';
                const createdOnDate = pipelineRow.createdOn ? formatDateTime(pipelineRow.createdOn) : '';

                return {
                    ...pipelineRow,
                    nodes: pipelineRow.nodes.toString(),
                    editedOn: editedOnDate,
                    createdOn: createdOnDate
                }
            });
            setTableData(newTableData)
        }
    }, [pipelineDraftData, userProfileData]);

    const onPipelineDelete = (pipelineId: string) => {
        const dataDataCopy = [...tableData];
        const newData = dataDataCopy.filter((pipeline: IPipelineTableRowData) => pipeline.id !== pipelineId);
        setTableData(newData);
    }

    // Update the Redux store with the tools data
    useEffect(() => {
        if (toolTableData) {
            dispatch(setToolsData(toolTableData));
        }
    }, [toolTableData]);

    const tabsHeaderTemplate = (options: TabPanelHeaderTemplateOptions) => {
        const styles = options.selected ? 'border-b-interactive border-b-2 text-black-900' : 'text-gray-700';
        return (
            <div className={styles}>
                {options.element}
            </div>
        )}

    const handleToolCreationSuccess = (newTool: any) => {

        // Check if newTool does not exist
        if (!newTool) {
            toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Tool data is missing!' });
            return;
        }

        // Construct the new tool object directly from newTool, serializing dates
        const updatedTool = {
            id: newTool._id || "",
            name: newTool.name || "New Tool",
            input: newTool.input || [],
            output: newTool.output || [],
            link: newTool.link || [],
            purposes: newTool.purposes || [],
            editedOn: new Date().toISOString(),
            dateSubmitted:  new Date().toISOString(),
        };

        // Add the newly created tool to the toolsData array
        const updatedToolsData = [...toolsData, updatedTool];

        // Dispatch the updated toolsData
        dispatch(setToolsData(updatedToolsData));

        // Show success notification
        toast.current?.show({ severity: 'success', summary: 'Success', detail: 'New tool created successfully!' });
    };

    const handleToolEditSuccess = (updatedTool: EditToolResponse) => {

        const t = [...toolsData];

        const replaceItemById = (arr: IToolTableDataResponse[], id: string, newItem: EditToolResponse) => {
            return arr.map(item => item.id === id ? newItem : item);
        }
        const newTools = replaceItemById(t, updatedTool.id, updatedTool);

        dispatch(setToolsData(newTools)); // Update the tools data with the edited tool
        setEditToolModalVisible(false); // Close the modal
        toast.current?.show({ severity: 'success', summary: 'Success', detail: 'Tool updated successfully!' });
    };

    return (
        <>
            <ToolInfoModal
                onHide={() => setToolInfoModalVisible(false)}
                visible={toolInfoModalVisible}
                institutionOptions={initializedFormData ? initializedFormData.toolUsers : []}
                onSuccess={handleToolCreationSuccess}  // Pass the success handler
            />
            <PipelineInfoModal
                onHide={ () => setPipelineInfoModalVisible(false) }
                visible={ pipelineInfoModalVisible }
                processDataTypeOptions={ initializedFormData ? initializedFormData.datatypes : [] }
                institutionOptions={ initializedFormData ? initializedFormData.toolUsers : [] }
            />
            <div className="w-full h-full flex">
                <ApiWrapper isLoading={ pipelineDraftDataLoading }
                            error={ pipelineDraftDataError }
                            data={ pipelineDraftData }
                            loadingLabel={ "Loading pipelines..." }
                            errorLabel={ "You must be logged in to use the Pipeline Builder" }
                >

                    <div className="w-full flex flex-col mx-auto px-[60px] py-[28px]">
                        <Toast ref={toast} />
                        <div className="flex justify-between items-center mb-5">
                            <h1 className="font-inter text-xl font-semibold text-black-900">Build</h1>
                            {activeTab === 0 && (
                                <Button
                                    data-testid={'newPipelineBtn'}
                                    label="New pipeline"
                                    icon="pi pi-plus text-icon"
                                    className="text-table_text bg-interactive rounded-md text-white font-bold py- px-4 pl-[12px] pr-[12px] pt-1 pb-1"
                                    onClick={() => setPipelineInfoModalVisible(true)}
                                />
                            )}
                            {activeTab === 1 && (
                                <Button
                                    data-testid={'newToolBtn'}
                                    label="New Tool"
                                    icon="pi pi-plus text-icon"
                                    className="text-table_text bg-interactive rounded-md text-white font-bold py- px-4 pl-[12px] pr-[12px] pt-1 pb-1"
                                    onClick={() => setToolInfoModalVisible(true)}
                                />
                            )}
                        </div>

                        <TabView
                            activeIndex={activeTab}
                            onTabChange={ (e) => setActiveTab(e.index) }
                            pt={{
                                root: {
                                    className: 'h-full flex flex-col rounded-lg border border-slate-300 border-solid'
                                },
                                panelContainer: {
                                    className: '!p-0 border border-top'
                                },
                                nav: {
                                    className: 'w-full flex !flex-row'
                                },
                                navContent: {
                                    className: 'w-full'
                                },
                            }}>
                            <TabPanel
                                header="Pipelines"
                                headerTemplate={tabsHeaderTemplate}
                            >
                                <PipelineTable data={ tableData } onPipelineDelete={onPipelineDelete} />
                            </TabPanel>
                            <TabPanel
                                header="Tools"
                                headerTemplate={tabsHeaderTemplate}
                            >
                                <ConfirmDialog
                                    visible={toolDeleteErrorModalVisible}
                                    onHide={() => setToolDeleteErrorModalVisible(false)}
                                    header='Error!'
                                    icon='pi pi-exclamation-triangle'
                                    message='This tool exists as a node within at least one pipeline. This tool cannot be deleted.'
                                    footer={
                                        <Button
                                            label="OK"
                                            className='bg-interactive text-white'
                                            onClick={() => setToolDeleteErrorModalVisible(false)}
                                        />
                                    }
                                />
                                <div>
                                    {toolTableData && (
                                        <ToolTable
                                            data={toolsData}
                                            onToolNameClick={handleToolNameClick}
                                            onToolDelete={deleteTool}
                                        />
                                    )}
                                    <EditToolModal
                                        onHide={() => setEditToolModalVisible(false)}
                                        visible={editToolModalVisible}
                                        institutionOptions={initializedFormData ? initializedFormData.toolUsers : []}
                                        toolData={selectedTool} // Pass the selected tool data to the modal
                                        onSuccess={handleToolEditSuccess} // Handle successful tool edit
                                    />
                                </div>
                            </TabPanel>
                        </TabView>
                    </div>
                </ApiWrapper>
            </div>

        </>
    );
};
