import React from "react";
import IMAGES from "../../assets/images/images.tsx";

export const LearnPage: React.FC = () => {
    return (
        <div className="max-w-[720px] mx-auto py-16 text-left font-normal text-sm font-inter space-y-5
         overflow-y-auto learn-page-scrollbar-hide">
            <h1 className="text-3xl font-semibold">
                About CoBE (Computational & Bench Scientist Ecosystem)
            </h1>

            <h2 className="text-xl leading-7">
                Transforming data into discoveries
            </h2>

            <p className="text-lg font-semibold leading-6">
                CoBE's Aims
            </p>

            <ol className="list-decimal list-outside pl-4">
                <li>Improve the reach and usage of computational tools by providing an intuitive, user friendly system
                    for documenting, sharing, and executing computational analysis.
                </li>
                <li>Empower bench scientists to confidently run computational analysis on biological data by promoting
                    the use of code ocean capsules for ‘one-click’ reproducible and barrier-free analysis (complete with
                    sample datasets and free cloud computing environments).
                </li>
                <li>Bridge the gap between software developers and computational biologists, and the software user,
                    freeing up time for all scientists to focus on what they do best.
                </li>
            </ol>

            <div>
                <iframe
                    className="w-full mx-0.5 h-80"
                    src="https://www.youtube.com/embed/sTbQL-mGIH8" // Replace VIDEO_ID with actual video ID
                    title="YouTube video"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
            </div>

            <h1 className="text-xl font-semibold leading-6">
                Who is CoBE intended for?
            </h1>

            <div>
                CoBE appeals to “bench scientists'' who need assistance finding and executing bioinformatics software.
                It also appeals to “computational scientists'' who want to increase the reach and impact of their
                software. CoBE grows in value to the bench scientists through the contributions from computational
                scientists.
                Conversely, CoBE grows in value to computational scientists because it gives access to a large pool
                of bench-scientists that use their tools. Every CoBE user and contribution increases the value of
                CoBE to all other users! If you’re still unsure whether CoBE is right for you, here are some use cases:
            </div>

            <ol className="list-decimal list-outside pl-4">
                <li>
                    <span className="font-bold">Computational Biologist</span> – any time you develop or publish
                    a new tool or pipeline, create an entry in CoBE so other groups can find and use your tools
                </li>
                <li>
                    <span className="font-bold">Computational Biologist</span> – any time you need to find
                    existing tools or pipelines to perform specific analysis, check CoBE to see if they already exist to
                    save
                    time and effort, or to serve as a jumping off point for your own analytic pipelines
                </li>
                <li>
                    <span className="font-bold">Bench Scientist</span> – if you need to figure out what kind of
                    analysis can be performed on your datasets, CoBE is a great platform to explore!
                </li>
                <li>
                    <span className="font-bold">Bench Scientist</span> – if you can't find a computational
                    biologist who has time to work on your project or analyze your data, try using CoBE to find Code
                    Ocean
                    capsules that can be easily executed without a coding background.
                </li>
            </ol>

            <p>
                For pipeline and tool builders, CoBE is intended as a platform for capturing and sharing
                computational resources that have been developed and thoroughly tested by the user. Ideally,
                sample data is included to improve reliability and reproducibility.
            </p>

            <p>
                CoBE does not provide data for analysis under any circumstance. We only provide coding and
                documentation resources supplied by CoBE users.
            </p>

            <p className="text-lg font-semibold leading-6">
                Code Ocean
            </p>

            <div>
                CoBE encourages the use of an external cloud computing platform (Code Ocean) to offer easy execution of
                computational pipelines and tools. If you need help generating Code Ocean capsules for your pipelines or
                tools, please reach out to the CoBE admin for assistance at <a href="mailto:admin@cobe.ca"
                                                                               className="text-blue-600 hover:underline">admin@cobe.ca</a>.
            </div>

            <div>
                Your institution likely limits the usage of cloud platforms for processing patient health information
                (PHI). Please follow your institution's policy on handling of PHI data when using Code Ocean or any
                other external platform accessed through CoBE.
            </div>

            <h1 className="text-xl font-semibold leading-6">
                CoBE How To: Getting Started
            </h1>

            <p className="font-bold">EXPLORE</p>
            <p>Search/View a pipeline</p>
            <ol className="list-decimal list-outside pl-4">
                <li>Click Explore</li>
                <li>Search for pipeline name, assay type of interest or keywords that relate a pipeline’s purpose</li>
                <li>Click into a pipeline to view tree diagram and node details</li>
                <li>Click the ‘View’ button to zoom into the tree diagram with node details in the panel on the right
                </li>
            </ol>

            <p>Search a tool</p>
            <ol className="list-decimal list-outside pl-4">
                <li>Click Explore</li>
                <li>Search for tool name or keywords that relate to a tool’s purpose</li>
                <li>The results page will display pipelines by default, click the Tools option to the left</li>
                <li>Click into a tool to view details</li>
            </ol>

            <div>
                Tip: Found a tool or pipeline that helped with your data analysis? Click into a tool or pipelines and
                hit the like button to highlight your favorite tools and pipelines, improve visibility, promote trust,
                and support the computational and bench scientist community.
            </div>

            <p className="font-bold">BUILD</p>
            <p>Create new tool</p>
            <ol className="list-decimal list-outside pl-4">
                <li>Click Build</li>
                <li>Sign in using UHN or Google account</li>
                <li>Select the Tools tab</li>
                <li>Click the ‘+ New Tool’</li>
                <li>Input important tool details</li>
                <li>Click the ‘Create new tool’ button</li>
            </ol>
            <div>Result: Your new tool will be added to the CoBE library and displayed in your tool build table.</div>

            <p>Create new pipeline</p>
            <ol className="list-decimal list-outside pl-4">
                <li>Click Build</li>
                <li>Sign in using UHN or Google account</li>
                <li>Click the ‘+ New Tool’</li>
                <li>Identify pipeline name, data type, assay type(s), and other important details</li>
                <li>Click the ‘Create new tool’ button</li>
            </ol>
            <div>Result: Your pipeline has been created and saved as a draft. You can continue building your pipeline by
                adding tools represented in the tree diagram as a node.
            </div>

            <p>Add tool to pipeline</p>
            <ol className="list-decimal list-outside pl-4">
                <li>Click on existing pipeline name in build table or create new pipeline</li>
                <li>In the builder workspace, click the ‘+’ on a terminal node to add a tool</li>
                <li>Search for any CoBE tool using the Tool panel on the right</li>
                <li>After selecting the desired tool, complete important details like the tool’s purpose,
                    inputs/outputs, or executable, doi, or documentation links
                </li>
                <li>Click ‘Save’ at the bottom of the Tool panel</li>
            </ol>
            <div>Result: Node will be saved and added to your pipeline. Please note, data will be lost if tool
                information is not saved before moving on to a new node.
            </div>

            <p>Delete tool in pipeline</p>
            <ol className="list-decimal list-outside pl-4">
                <li>In the builder workspace, click on the node you wish to delete</li>
                <li>In the panel on the right, select the 3 dots (…) for more options</li>
                <li>Select the delete option and confirm deletion</li>
            </ol>
            <div>Result: Node will be removed from the builder workspace. Please note, any nodes downstream will also be
                deleted.
            </div>

            <p>Submit pipeline</p>
            <ol className="list-decimal list-outside pl-4">
                <li>In the builder workspace, click the ‘Submit’ button when you have fully captured your pipeline and
                    are happy with its details.
                </li>
                <li>Confirm the submission by click on the ‘Yes, submit draft’ button</li>
            </ol>
            <div>Result: Your pipeline is updated from ‘Draft’ to ‘Submitted’ and will be reviewed by a CoBE
                administrator. Please note, the administrator may contact you by email to request changes and ensure
                your pipeline is verified before it is approved and published to the CoBE community. If this is the
                case, your pipeline will have a status of ‘Changes Requested’ chip.
            </div>

            <p className="text-lg font-semibold leading-6">
                Contact Us
            </p>

            <div>
                Do you have any questions about how to use CoBE? Can’t find the data type or assay type you need? Do you
                need assistance creating Code Ocean capsules? For these, or any other questions, please reach out to us
                at <a href="mailto:admin@cobe.ca" className="text-blue-600 hover:underline">admin@cobe.ca</a>
            </div>

            <hr className="border-t"/>

            <div className="flex justify-between items-center">
                <img src={IMAGES.uhn_logo} alt="UHN Logo" className="h-6"/>
                <img src={IMAGES.cobe_horizontal_logo} alt="Logo" className="h-6"/>
            </div>

        </div>
    )
}
