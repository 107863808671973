import React, {useEffect, useRef, useState} from "react";
import { useParams } from "react-router-dom";
import { useGetPipelineById } from "../../hooks/useGetPipelineById.tsx";
import { ApiWrapper } from "../../components/ApiWrapper/ApiWrapper.tsx";
import { Button } from "primereact/button";
import { IExecutableLink, IPipeline, IPurposeTool, ITool } from "../../hooks/useSearch.tsx";
import { PipelineFlowViewer } from "../../components/ReactFlow/PipelineFlowViewer.tsx";
import { PipelineNodeDetailList } from "../../components/PipelineDetails/PipelineNodeDetailList.tsx";
import { INode } from "../../models/INode.ts";
import { IFormattedData } from "../../models/IFormattedData.ts";
import { IInfoNode } from "../../models/Graph/IInfoNode.ts";
import { useGetExecutableLikesForPipeline } from "../../hooks/useGetExecutableLikesForPipeline.tsx";
import { UserAvatarGroup } from "../../components/UserAvatarGroup/UserAvatarGroup.tsx";
import { CodeLinkSplitButton } from "../../components/CodeLinkButton/CodeLinkSplitButton.tsx";
import { LikeButton } from "../../components/LikeButton/LikeButton.tsx";
import { DocumentLikeDataType } from "../../models/DocumentLikeDataType.ts";
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import {PipelinePDF} from "../../pdf/PipelinePDF.tsx";
import {PDFDownloadLink} from "@react-pdf/renderer";

export const toolType = [
    'software',
    'rLibrary',
    'rWorkflow',
    'rDb',
    'pythonScript',
    'website',
    'database'
];

export const PipelineDetailPage: React.FC = () => {
    const { id } = useParams();
    if (!id) {
        return null;
    }
    const { getPipelineById, isLoading, data, error } = useGetPipelineById(id);
    const {
        getExecutableLikesForPipeline,
        isLoading: likesIsLoading,
        data: likesDataResponse,
        error: likesError
    } = useGetExecutableLikesForPipeline(id);
    const [formattedData, setFormattedData] = useState<IFormattedData | null>(null);
    const [openInLink, setOpenInLink] = useState<IExecutableLink | null>(null);
    const [likesData, setLikesData] = useState<DocumentLikeDataType>({ likes: 0, userLiked: false });
    const printRef = useRef<HTMLDivElement | null>(null);  // Ref for the whole page

    // Get pipeline by id on page load
    useEffect(() => {
        getExecutableLikesForPipeline();
        getPipelineById();
    }, []);

    // When data changes, format and set the formatted nodes state
    useEffect(() => {
        if (data) {
            const formattedNodes = formatNodeObjects(
                data.pipeline ? [data.pipeline] : [],
                data.tools,
                data.graph.nodes
            );
            const newFormattedData: IFormattedData = {
                ...data,
                graph: {
                    ...data.graph,
                    nodes: formattedNodes,
                },
                // Original code makes this a list of pipelines, but we only have one pipeline (?) leaving as is in case
                pipelines: data.pipeline ? [data.pipeline] : []
            };
            setFormattedData(newFormattedData);
        }

    }, [data]);

    // Update likes data state when likes data response changes
    useEffect(() => {
        if (likesDataResponse) {
            setLikesData(likesDataResponse);
        }
    }, [likesDataResponse]);

    /**
     * Adds info property to the nodes.
     * @param {IPipeline[]} pipelines
     * @param {ITool[]} tools
     * @param {INode[]} nodes
     * @param {boolean} isBuilder
     * @returns formatted nodes
     */
    const formatNodeObjects = (pipelines: IPipeline[], tools: ITool[], nodes: INode[], isBuilder: boolean = false): IInfoNode[] => {
        const formattedNodes: IInfoNode[] = assignNodeInfo(pipelines, tools, nodes);
        return formattedNodes;
    };

    /**
     * Formats node.info object so that it can be properly displayed on the tooltip.
     * @param {IPipeline[]} pipelines - Array of pipeline objects.
     * @param {ITool[]} tools - Array of tool objects.
     * @param {INode[]} nodes - Array of node objects.
     * @returns {IInfoNode[]} nodes with info object.
     */
    const assignNodeInfo = (pipelines: IPipeline[], tools: ITool[], nodes: INode[]): IInfoNode[] => {
        // find tool nodes for given pipelines to add input/output to the nodes.
        let toolNodes: any[] = [];
        for (let pipeline of pipelines) {
            // Collect tool nodes from the main pipeline where they have any input or output
            toolNodes = toolNodes.concat(
                pipeline.main.nodes.filter(
                    (node: any) =>
                        ( node.input && node.input.length > 0 ) ||
                        ( node.output && node.output.length > 0 )
                )
            );
            // Collect tool nodes from sub-branches of the pipeline where they have any input or output
            for (let branch of pipeline.subBranches) {
                toolNodes = toolNodes.concat(
                    branch.nodes.filter(
                        (node: any) =>
                            ( node.input && node.input.length > 0 ) ||
                            ( node.output && node.output.length > 0 )
                    )
                );
            }
            // Collect tool nodes from purposes of the pipeline where they have any input or output
            for (let purpose of pipeline.purposes) {
                toolNodes = toolNodes.concat(
                    purpose.nodes.filter(
                        (node: any) =>
                            ( node.input && node.input.length > 0 ) ||
                            ( node.output && node.output.length > 0 )
                    )
                );
            }
        }
        // Initialize an array to store nodes with added info.
        const infoNodes: IInfoNode[] = [];

        // Loop through each node to add info.
        for (let node of nodes) {
            // Check if the node type is in the list of tool types.
            if (toolType.includes(node.type)) {
                // Find the tool corresponding to the node.
                let found = tools.find(
                    (item: ITool) => item._id.toString() === node.toolId.toString()
                );
                if (found) {
                    // Find the corresponding tool node.
                    let foundNode = toolNodes.find((item) => item.id === node.id);
                    // Find the purpose information for the node.
                    let toolPurpose = found.purposes.find(
                        (purpose: IPurposeTool) => purpose.text === node.purpose
                    );
                    // Create a new node with additional info.
                    const newInfoNode: IInfoNode = {
                        ...node,
                        info: {
                            version: node.version,
                            purpose: node.purpose,
                            keywords: found.keywords,
                            input: foundNode.input,
                            output: foundNode.output,
                            score: found.score,
                            link: found.link,
                            doi: found.doi,
                            executableLinks: toolPurpose ? toolPurpose.executableLinks : [],
                        },
                    };
                    // Add the new node to the array.
                    infoNodes.push(newInfoNode);
                }
            }
            else if (node.type === 'datatype' || node.type === 'origin') {
                // Create a new node with additional info.
                const newInfoNode: IInfoNode = {
                    ...node,
                    info: {
                        version: node.version,
                        purpose: node.purpose,
                        keywords: [],
                        input: [],
                        output: [],
                        score: 0,
                        link: '',
                        doi: '',
                        executableLinks: [],
                    },
                };
                // Add the new node to the array.
                infoNodes.push(newInfoNode);
            }
        }
        // Return the array of nodes with added info.
        return infoNodes;
    };

    /**
     * Formats user's names into a string list for the user avatar group
     * @param names - The list of names to format
     * @returns The formatted string
     */
    function formatNames(names: string[]): string {
        const totalNames = names.length;

        switch (totalNames) {
            case 0:
                return "No names available";
            case 1:
                return names[0];
            case 2:
                return names.join(" & ");
            default:
                const remainingCount = totalNames - 2;
                return `${names[0]}, ${names[1]} & ${remainingCount} more`;
        }
    }

    const passThroughProps = {
        root: {
            className: 'text-white bg-blue-500 border border-blue-500 hover:bg-blue-600 hover:border-blue-600\': props.severity === \'info\' && !props.text && !props.outlined && !props.plain'
        },

    };

    return (
        <div id="pipeline-detail-page" className="h-full overflow-scroll w-full">
            <ApiWrapper errorLabel={'Error loading pipeline'} isLoading={ isLoading } error={ error } data={ data } loadingLabel={ "Loading Pipeline..." }>
                { formattedData &&
                    <div className="flex flex-col items-center gap-5 py-7">
                        {/* Top bar */}
                        <div className="title w-2/3">
                            <div className="flex w-full justify-center">
                                <div className="flex flex-row w-full">
                                    <div className="flex-1">
                                        <div className="text-neutral-800 text-2xl font-semibold font-inter leading-7 pb-2">{ data?.pipeline.name }</div>
                                        <div className="flex flex-row items-center">
                                            <UserAvatarGroup users={ data.pipeline.user }/>
                                            <p className="text-neutral-500 text-sm font-normal font-inter leading-tight">
                                                {
                                                    formatNames(data.pipeline.user.map(user => user.name))
                                                }
                                                {/*| {likesData ? likesData.likes : "..."} { likesData && likesData.likes !== 1 ? "Likes" : "Like" } | Used by 150 people*/}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex flex-row items-center gap-2">
                                        {
                                            likesData &&
                                            <LikeButton
                                                likeCount={ likesData.likes }
                                                isLiked={ likesData.userLiked }
                                                isClickable={ true }
                                                likeActionDetails={
                                                    {
                                                        datatype: "pipeline",
                                                        datatypeId: id,
                                                        onLikesChanged: (newLikes) => {
                                                            setLikesData(newLikes);
                                                        }
                                                    }
                                                } />
                                        }

                                        {
                                            // If there are executable links, show the code link button
                                            data?.pipeline.main.executableLinks &&
                                            data?.pipeline.main.executableLinks.length > 0 &&
                                            <CodeLinkSplitButton executableLinks={data.pipeline.main.executableLinks} />
                                        }
                                        <PDFDownloadLink
                                            document={<PipelinePDF formattedData={formattedData} id={id} />}
                                            fileName={`Pipeline-${data?.pipeline.name}.pdf`}
                                        >
                                            {({ loading }) =>
                                                loading ? (
                                                    <Button
                                                        pt={passThroughProps}
                                                        className="h-[32px] !py-0 bg-pipeline_diagram_view_button text-white rounded shadow border border-pipeline_diagram_view_button"
                                                        label="Generating PDF..."
                                                    />
                                                ) : (
                                                    <Button
                                                        pt={passThroughProps}
                                                        className="h-[32px] !py-0 bg-pipeline_diagram_view_button text-white rounded shadow border border-pipeline_diagram_view_button"
                                                        label="Export"
                                                    />
                                                )
                                            }
                                        </PDFDownloadLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Pipeline Preview */}
                        <div className="w-4/5 h-96 ">
                            <div
                                className="w-full h-full border flex items-center justify-center rounded-lg">
                                <PipelineFlowViewer
                                    isExpandable={ true }
                                    formattedData={ formattedData }
                                    openInLink={ openInLink ? openInLink.executableLink : '' }
                                />
                            </div>

                        </div>
                        {/* Tool detail list */}
                        <div className="w-2/3">
                            <PipelineNodeDetailList formattedData={ formattedData }/>
                        </div>
                    </div>
                }
            </ApiWrapper>
        </div>
    );
};
