import {useAxios} from "./useAxios.tsx";

export const useGetDecisionTree = () => {
    const { commonFetch, isLoading, data, error } = useAxios<any>({
        url: "view/decision-tree",
        method: "GET",
    });

    const getDecisionTree = async (queryParams: any) =>  await commonFetch({queryParameters: queryParams});

    return { getDecisionTree, isLoading, data, error };
}
